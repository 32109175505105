/**
 * @file
 * @author  fangsimin
 * @date    2020-02-09 21:29:35
 * @last Modified by    fangsimin@baidu.com
 * @last Modified time  2021-06-25 15:32:10
 */
import state from './state-cn';
import country from './country-cn';
export default {
    permissions: {
        legalReTip: '公司注册所在国家/地区',
        legalPerson: '法人所在地',
        companyLegalPerson: '公司法人所在地',
        roleList: '角色清单',
        roleName: '角色名称',
        rolePrivileges: '角色权限',
        announcementEmail: '信息同步邮箱'
    },
    newDashboradBreadCrumbs: {
        account: '主页',
        companys: '公司',
        accounts: '账户',
        campaign: '广告计划',
        campaigns: '广告计划',
        ad: '广告',
        billCampaign: '活动'
    },
    menuData: {
        oppoSDK: 'oem 报表',
        oppoSDKTitle: 'oem 报表',
        popInCreativeMakerTitle: 'popIn Creative Maker',
        receivablesTitle: 'Discovery 应收款',
        receivables: 'Discovery 应收款',
        monthlyBill: '对外月度账单',
        monthlyBillCpm: '对外月度账单广告纬度',
        monthlyBillAd: '对外月度账单活动维度',
        monthlyBillTitle: '对外月度账单',
        msgCenter: '接收通知',
        notification: '通知中心',
        notificationTitle: '通知中心',
        monthBillTitle: '月度账单',
        amConfigTitle: '运营配置',
        amConfig: '运营配置',
        minBid: '为优化广告效果，目标出价不能少于{minBid}',
        readCreateTitle: '创建READ 代码',
        discoveryReadCreateTitle: '创建READ 代码',
        brandReason: '品牌名称在优质媒体上运行不安全，请联系您的客户经理了解详情',
        imgReason: '此广告系列因图片或标题不符合要求而被拒绝',
        campaignReason: '此广告系列因着陆页不合规而被拒绝',
        textOnImageReason: '广告素材违反媒体政策，请联系你的客户经理获取详细信息。',
        msnRejectionReason: '该广告创意因不符合部分媒体审核规则而被拒审',
        sexualRelatedReason: '该广告创意因涉及性相关违规问题而被拒审',
        tobaccoProductsReason: '该广告创意因涉及未成年人违规问题而被拒审',
        textOnImageMediaType: 'MSN审核标签过滤',
        tagReason: '此活动被优质发布商拒绝，因为所推广的产品属于受限广告类别 - （{tagName}）',
        uploadWaring: '平台服务近期将进行6小时的定期维护，',
        uploadWaringMore: '点击了解详情。',
        uploadText: ' 平台服务将在EST时区2022年1月15号晚上10：00到2022年1月16号上午4：00点之间进行6小时的定期维护，我们建议您相应地规划广告系列。<br><br>覆盖时区 ：<br><br>UTC+8：2022年1月16号上午11：00到2022年1月16号下午5：00<br>UTC+0：2022年1月16号上午3：00到2022年1月16号上午9：00',
        multiDimension: '多维度数据报表',
        multiDimensionTitle: '多维度数据报表',
        wrongList: '你所批量添加的{wrongList}等domains不正确，不正确域名将不会生效',
        downList: '点击此处下载文档',
        domainSuccess: '你上传的domain中有{correctLenght}个成功命中',
        searchSimilarBtnTitle: '批量审核',
        searchSimilar: '批量审核',
        stripeActionTitle: 'Stripe',
        PaymentManagement: '帐款管理',
        PaymentManagementTitle: '帐款管理',
        ROIAnalysisTitle: 'ROI报表',
        campaignTitle: '创建广告',
        newCampaignTitle: '新创建广告',
        dashboardTitle: '控制台',
        new_dashboardTitle: '新控制台',
        auditTitle: '审核广告',
        ecommerceToolTitle: '电商工具',
        lPManagementTitle: '内容管理',
        oPManagementTitle: 'OP管理',
        productManagementTitle: '产品管理',
        domainManagementTitle: '域名管理',
        testTitle: '测试',
        visualTestTitle: '可视化测试',
        visualTestBannerTitle: 'Banner可视化测试',
        generateLocalPageWrapper: '生成落地页网页',
        generateLocalPageWrapperTitle: '生成落地页网页',
        checkStatusTitle: '广告召回状态',
        administrator: '管理员',
        financialTitle: '财务管理',
        monthlyBillRelease: '月度账单发布',
        monthlyBillSave: '月度账单保存',
        creditLineTitle: '财务风控',
        roiMgrTitle: '财务报表',
        incomeTitle: '应收款',
        payTitle: '应付款',
        offlineTitle: '离线管理',
        agencyManagementTitle: '代理管理',
        applyTitle: '应用标题',
        permissionTitle: '权限管理',
        accountTitle: '账户管理',
        userTitle: '用户权限',
        bdOpenAccountTitle: 'BD开户',
        roleTitle: '角色管理',
        balance: '总可用额度',
        adListTitle: '广告',
        dataReportTitle: '数据报表',
        amTitle: '运营平台',
        amDashboardTitle: '运营分析',
        icafeTitle: '事项追踪',
        sampleTitle: '抽样平台',
        adTooltipText: '你至少选择一条广告计划',
        pixel: '转化追踪',
        pixelTitle: '转化追踪',
        pixelConversion: '转化',
        conversionListTitle: 'Conversion & Read',
        readListTitle: 'Read',
        conversionCreateTitle: '创建转化',
        pixelCreate: '创建转化',
        account: '个账户',
        accounts: '个账户',
        rechargeTitle: '账户充值',
        payActionTitle: '充值',
        stripeAction: 'stripe',
        showbilling: 'stripe自动充值',
        helpCenterTitle: '帮助中心',
        gettingStartTitle: '开始',
        createAndManageCampaignTitle: '广告计划管理',
        priceAndBillTitle: '出价&充值',
        qaCenterTitle: '内部常见问题',
        faqTitle: '常见问题',
        adPolicyTitle: '广告政策',
        reportAndOptimizingTitle: '报告&优化',
        diagnosisTitle: '广告诊断',
        bidAnalysisTitle: '竞价分析',
        pageBidTitle: '广告位出价配置',
        monthlyReportTitle: '运营月报',
        customerManagementTitle: '客户管理',
        wraningWrod: '请注意：目标出价高于正常范围',
        cpcWraningWrod: 'CPC由{cpc}调整为{cpcData}调整幅度超过正常水平是否继续',
        dayWraningWrod: '请注意：您将日预算由{day}调整到{dayData}，调整幅度超出正常水平，请确认是否继续进行？',
        conversions: 'Pixel Conversion',
        conversionsTitle: 'Pixel Conversion',
        postback: 'S2S Postback',
        postbackTitle: 'S2S Postback',
        siteTip: '由于campaign下某些site CPC低于{siteCpc}，其将会自动调整为{siteCpc}',
        cpcTips: '请注意，此处为账户交易币种',
        cpcTips2: ''
    },
    login: {
        emailLogin: '使用邮箱登录',
        thirdPartyLogin: '第三方账户登录',
        forgetPwd: '忘记密码',
        haveNoAccount: '还没有账号？',
        register: '点击注册',
        email: '邮箱',
        pwd: '密码',
        loginText: '登录',
        pwdBefore: '原密码',
        pwdAfter: '新密码',
        confirmPwd: '确认密码',
        account: '账号',
        loginEmail: '登录邮箱'
    },
    button: {
        yes: '是',
        no: '否',
        subimtAudit: '提交送审',
        subimtAuditTitle: '(审查需1~3个工作日)',
        backCheck: '返回检查',
        confrimReject: '确认拒审',
        search: '查看',
        addCreative: '新增素材',
        trash: '删除',
        reachOfLandingPage: 'Landing Page 到达率',
        checkoutConversion: 'Checkout转化率',
        budgetSpend: '预算消耗率',
        offerConversion: 'Offer转化率',
        purchaseConversion: 'Purchase转化率',
        apiToken: '复制ApiToken',
        filterCols: '设置列',
        export: '导出',
        reset: '重置',
        apply: '应用',
        confirm: '确认',
        cancel: '取消',
        previous: '上一页',
        next: '下一页',
        submit: '提交',
        audit: '申诉',
        revise: '修改广告计划',
        total: '共',
        edit: '编辑',
        operate: '操作',
        detail: '详情',
        authorize: '授权',
        adminAuthorize: 'Admin角色授权',
        advertiseAuthorize: '仅广告主角色授权',
        ignore: '忽略',
        add: '新增',
        query: '查询',
        recreate: '重新创建广告计划',
        createConversion: '创建转化',
        createCampaign: '创建广告',
        UpdateConversion: '更新转化配置',
        archived: '归档',
        editMore: '编辑更多',
        delete: '删除',
        pass: '通过',
        copy: '复制',
        import: '导入',
        create: '创建',
        history: '历史记录',
        pageAudit: '审核',
        preview: '预览',
        rename: '重命名',
        sendReport: '发送报告',
        save: '保存',
        notSave: '不保存',
        openEditor: '打开编辑器',
        deleteTitle: '是否删除',
        deletePage: '提示',
        innerStatus: '休眠状态'
    },
    dashboard: {
        totalBalance: '总可用额度',
        balanceTip1: '部分账户总可用额度即将消耗完毕，请联系您的客户经理进行充值，否则将会影响广告正常投放',
        balanceTip2: '部分账户总可用额度已经消耗完毕，请联系您的客户经理进行充值，否则将会影响广告正常投放',
        balanceTip3: '部分账户总可用额度即将或已经消耗完毕，请联系您的客户经理进行充值，否则将会影响广告正常投放',
        totalBalanceTip1: '账户总可用额度即将消耗完毕，预计可用<span style="color: #e92754;">{num}</span>天，请联系您的客户经理进行充值，否则将会影响广告正常投放',
        totalBalanceTip2: '账户总可用额度已消耗完毕，请联系您的客户经理进行充值，否则将会影响广告正常投放',
        balanceStatus: '余额状态',
        sufficientBalance: '余额充足',
        lowBalance: '余额较低',
        tightBalance: '余额紧张',
        insufficientBalance: '余额不足',
        totalBalanceTip: '总可用额度=累计充值+信用额度+coupon-累计花费。总可用额度并非账户实际资产，实际资产请以账单为准',
        searchDetail: '查看详情',
        collapseDetail: '收起详情',
        smbLearningCompletedTip: '智能出价学习成功，不支持修改cpc',
        maxcvCpcTip: 'MaxCV模式，不支持修改cpc',
        siteBlockAccountContentTip: '请注意一旦您进行了某个账户级别的域名屏蔽操作，您将无法解除该账户下广告级别的domain屏蔽。',
        siteBlockCampaignContentTip: '请注意一旦您进行了某个广告计划级别的域名屏蔽操作，您将无法在账户级别解除此domain屏蔽，必须在该广告计划级别解除此domain。',
        viewSelected: '查看所选',
        adReview: '已选{num}个广告',
        assetCampaign: '已选{num}个广告系列',
        assetReview: '已选{num}个广告计划',
        accountReview: '已选{num}个账户',
        campaignReview: '已选{num}个广告计划',
        specifyCampaignTip1: 'Campaign id不正确',
        specifyCampaignTip2: '该活动已作为新活动生效一键继承，请重新选择活动',
        specifyCampaignTip3: '该活动过去6天CV<3未能达到一键继承的标准，请重新选择活动',
        specifyCampaignTip4: '该活动下不存在近14天 cv≥3 的创意，请重新选择活动',
        specifyCampaignTip5: 'Country、platform、OS、Browser设置不一致，学习效果可能不佳，确定要继续提交吗？',
        assignMatch: '手动指定',
        inheritCampaignId: '广告计划ID',
        inherit: '一键继承',
        matchType: '匹配方式',
        autoMatch: '自动匹配',
        exceedLimitTip: '超过账户纬度可生效一键继承活动数量上限',
        notInheritTip: '不满足一键继承前提条件，是否剔除后继续提交剩余活动',
        notInheritTip2: '该广告计划不满足一键继承前提条件',
        matchDegree: '匹配度',
        excellent: '优',
        fine: '良',
        noMatch: '未匹配到一键继承素材',
        matching: '正在匹配中',
        inheritEnd: '已结束继承',
        dateHourTip: '小时级数据最长支持查询两周',
        ocpcphase: '当前活动已进入智能出价第二阶段',
        ocpcNewPhase: '当前活动已进入智能出价',
        dateRemind: '转化相关数据存在时延，最新更新时间为:{year}-{month}-{data} {hour}:{minute} {meridian} <span class="date-remind-color">({timezone})</span>',
        dataRemindColor: '({timezone})',
        downloadTypeDay: '天级报表',
        downloadTypeSummary: '汇总报表',
        accountFilter: '账户筛选',
        customerSource: '客户来源',
        vcpm: 'vCPM',
        country: '国家筛选',
        readChart: 'READ 分析',
        readChartTitle: 'READ 报表',
        total: '总计',
        account: '账户',
        allAccounts: '全部账户',
        colManagement: '管理',
        colCampaigns: '广告计划',
        colAds: '广告',
        colCost: '消耗',
        colTrafficAcquisition: '流量获取',
        colAudienceMetrics: '受众指标',
        mcv: 'MCV',
        cv: '转化',
        cvr: '转化率',
        mcvr: 'MCVR',
        mcpa: '次级转化成本',
        adSize: '尺寸',
        colTargeting: 'Targeting',
        dimensionFilter: '多维度筛选',
        conversiontype: '转化类型',
        newConversiontype: 'Discovery Conversion (MCV)',
        mediagoCv: 'MediaGo Conversion (Lead&purchase)',
        currencyType: '多货币切换',
        colPerformance: '数据表现',
        payPal: 'PayPal',
        StripeVisa: 'Stripe-Visa',
        StripeMastercard: 'Stripe-Mastercard',
        productName: '产品名称',
        otherPage: '待解析域名',
        blocked: '(已在账户维度屏蔽)',
        adListColSize: 'ad设置列 尺寸 选项',
        auditPublisher: '审核详情媒体分类',
        domainSspColumn: '域名页ssp属性',
        dashboardDomainBlock: '域名屏蔽',
        uniformlyFlag: '投放速度',
        campaign: '广告计划',
        campaignName: '广告计划名称',
        status: '投放状态',
        adStatus: 'Ad投放状态',
        campaignStatus: '投放开关',
        spend: '花费',
        impression: '真实展示',
        click: '点击',
        clickRate: '点击率',
        startTime: '开始时间',
        endTime: '结束时间',
        searchPlaceholder: '输入广告计划名称',
        filterBtn: '筛选',
        refreshBtn: '刷新',
        viewSelectedBtn: '查看所选',
        editSelectedBtn: '编辑所选',
        review: '审核详情',
        discarded: '这条广告已被归档',
        reviewResult: '审核结果',
        reviewAssetWaiting: '广告素材正在等待审核， 我们将在3个工作日内完成审核。请耐心等待…',
        reviewAssetNotPass: '该广告素材因违反政策无法投放，<br/>点击右侧&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按钮了解详情',
        reviewAssetReason: '该广告素材因违反政策无法投放',
        reviewAssetReason1: '该广告素材可以投放，但由于受政策限制而不能在所有情况下展示',
        reviewAssetNotPass1: '该广告素材可以投放，但由于受政策限制而不能在所有情况下展示，`<br/>点击右侧&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按钮了解详情',
        discardedAsset: '这条素材已被归档',
        reviewWaiting: '广告正在等待审核，<br/>我们将在3个工作日内完成审核。请耐心等待…',
        sppNotWork: '广告计划状态异常，无法投放，请及时联系AM修复',
        reviewHasRemain: '{wait_audit_asset_num}个（共{asset_num}个）广告素材处于"待审中"状态 <br/>',
        reviewWaiting1: '广告计划下部分素材正在审核中，不影响广告计划下已过审素材正常投放，<br/>我们将在3个工作日内完成全部审核。请耐心等待…',
        reviewNotPass: '该Campaign中有素材未通过审核，<br/>点击右侧&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按钮了解详情',
        reviewNotPass1: '该Asset中有素材未通过审核，<br/>点击右侧&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按钮了解详情',
        newReviewNotPass1: '广告计划下部分素材可以投放，但由于受政策限制不能在所有情况下展示，<br/>点击右侧&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按钮了解详情',
        newReviewAssetWaiting: '该广告素材可以投放，但由于受政策限制而不能在所有情况下展示',
        newReviewNotPass: '广告计划下部分素材因违反政策无法投放，<br/>点击右侧&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按钮了解详情',
        // reviewNotPass: '审核未通过，<br/>请联系你的账户管理员',
        auditDetails: '详情',
        campaignFilter: '广告计划筛选',
        adSetFilter: '广告组筛选',
        adFilter: '广告筛选',
        datetimeRange: '时间范围',
        and: '至',
        selectDate: '选择时间',
        on: '开启',
        off: '关闭',
        CPC: 'CPC',
        totalBudget: '总预算',
        roas: 'ROAS',
        dailyBudget: '天预算',
        campaignDetails: '广告计划详情',
        campaignCopy: '复制广告计划',
        adSetsDetails: '广告组详情',
        adsDetails: '广告详情',
        campaignEdit: '广告计划编辑',
        adCopy: '复制广告',
        adEdit: '广告编辑',
        creative: '素材',
        utcStandard: 'UTC标准时间',
        objective: '目标',
        accountsName: '账户名',
        adCreator: '广告创建人',
        // audit
        assetTitle: '标题',
        asset: '图片',
        assetName: '素材名称',
        adInformation: '广告信息',
        accountName: '账户名称',
        adName: '广告名称',
        submitTime: '创建时间',
        start: '投放时间',
        userId: '用户ID',
        reviewItems: '审核内容',
        pass: '通过',
        fail: '部分广告被拒绝',
        invalidReason: '拒审原因',
        comments: '审核员建议',
        reviewer: '审核人',
        reviewTime: '审核时间',
        colCfgTitle: '设置列',
        colDimension: '维度',
        colAdPerformance: '广告数据表现',
        colContentPerformance: '内容数据表现',
        campaignId: '广告计划ID',
        accountId: '账户ID',
        colStatus: '投放状态',
        colStart: '开始时间',
        colEnd: '结束时间',
        colSubmissionTime: '提交时间',
        colSpend: '花费',
        // colBalance: '可用额度',
        colBalance: '活动剩余预算',
        colTotalBudget: '总预算',
        colDailyBudget: '天预算',
        colDetail: '详情',
        colDelivery: 'Delivery',
        colOther: '其他',
        colCpc: 'CPC',
        colAvgCpc: '平均CPC',
        colVCpm: 'vCPM',
        colImp: '真实展示',
        colClick: '点击',
        colCtr: '点击率',
        colConversion: '转化',
        newColConversion: '转化',
        cvViewContent: '查看内容',
        cvAppInstall: '安装',
        cvCompleteRegistration: '完成注册',
        cvAddToCar: '加入购物车',
        cvAddPaymentInfo: '输入支付信息',
        cvSearch: '搜索',
        cvStartCheckout: '开始结账',
        cvPurchase: '购买',
        cvAddToWishlist: '加入意愿清单',
        cvLead: '潜在用户',
        cvOther: '其它',
        colAccount: '账户信息',
        conversions: '转化',
        conversion: '转化',
        conversionRate: '转化率',
        colConversionRate: '转化率',
        costPerConversion: '转化成本',
        colConversionPrice: '转化成本',
        newConversion: '转化',
        newConversions: '转化',
        newConversionRate: '转化率',
        newColConversionRate: '转化率',
        newColAgency: '代理店',
        newColAgencyInput: '输入代理店名称',
        newColAgencyName: '代理店名称',
        newColAgencyId: '代理店ID',
        newCostPerConversion: '转化成本',
        newColConversionPrice: '转化成本',
        campaignMultiEdits: {
            selectIdsNum: '正在编辑1条广告计划 | 正在编辑{num}条广告计划',
            selectAssetIdsNum: '正在编辑1条创意 | 正在编辑{num}条创意',
            dialogTitle: '批量编辑',
            status: '状态',
            bid: '出价',
            perCampaign: '每个广告系列',
            dailySpendCap: '日预算',
            lifetimeSpendCap: '总预算',
            bulkEditSuccess: '批量编辑campaign成功',
            assetBulkEditSuccess: '批量编辑creative成功',
            bulkArchivedSuccess: '批量归档campagin成功',
            assetBulkArchivedSuccess: '批量归档creative成功'
        },
        accountList: {
            searchPlaceholder: '输入账户名称'
        },
        adList: {
            head: '广告列表',
            adNum: '已创建：$num条',
            id: '广告Id',
            status: '投放状态',
            fileName: '文件名称',
            name: '素材名称',
            image: '创意',
            size: '尺寸',
            title: '标题',
            brandName: '品牌名称',
            spend: '花费',
            impression: '真实展示',
            ctr: '点击率',
            url: '网页链接',
            searchPlaceholder: '输入标题/广告Id/URL',
            searchAssetId: '输入标题/广告系列id/URL',
            click: '点击数',
            webUrl: '网页链接',
            cpc: 'CPC',
            campaignId: '广告计划ID',
            campaignName: '广告计划名称',
            assetId: '广告系列ID',
            tableFooterMsg: '{n}条广告正在创建中',
            failedMsg: '图片后期处理失败'
        },
        domain: {
            domainName: 'Site',
            spend: '花费',
            impression: '真实展示',
            click: '点击数',
            ctr: '点击率',
            vcpm: 'vCPM',
            acpc: '平均CPC',
            suggestedCpc: '建议CPC',
            cpc: 'CPC',
            searchPlaceholder: '搜索域名',
            blockBtn: '屏蔽',
            blockDisableBtn: '屏蔽（无操作权限）',
            resumeBtn: '解除屏蔽',
            resumeDisableBtn: '解除屏蔽（无操作权限）',
            resumeDisableAccountBtn: '请回到账户层级进行解除',
            ssp: 'SSP',
            domainNameRemark: '域名维度的数据更新会有2个小时的延迟'
        },
        domainTitle: 'Sites',
        // data report column setting
        colBreakDownBy: '细分维度',
        colCampaignAds: '广告信息',
        suffixBreakDownBy: '你最多可以选择2个维度',
        colData: '数据指标',
        colEarning: '收入',
        yesterday: '昨天',
        today: '今天',
        last7days: '过去7天',
        last30days: '过去30天',
        archivedMsg: '归档后，您将不能再编辑这条广告，但广告数据会保留',
        archivedMsg2: '归档后，您将不能再编辑这些广告，但广告数据会保留',
        archivedAssetMsg: '归档后，您将不能再编辑这些创意，但创意数据会保留',
        filterList: '广告计划列表设置',
        adFilterList: '广告列表设置',
        hideArchivedCmps: '隐藏归档广告',
        hideArchivedAds: '隐藏归档广告',
        onOffBoth: '展示所有广告',
        adOnOffBoth: '展示所有广告',
        showAllCampaigns: '展示所有广告（包括归档广告）',
        onlyArchivedCmps: '只展示归档广告',
        showAllAds: '展示所有广告（包括归档广告）',
        onlyArchivedAds: '只展示归档广告',
        selectedCampaigns: '已选择{num}条',
        adInfo: '{num}条广告计划',
        domainCampaignTip: '{num}条广告计划',
        domainAccountTipAll: '所有账户',
        domainAccountTip: '{num}个账户',
        mediaBlackWhiteListTitle: '媒体账户/Site白名单',
        mediaType: '媒体类型',
        selectedZipCode: '邮编: {num} 个',
        campaignSetOcpc: 'OCPC',
        campaignSetEcpc: 'ECPC',
        bidByDomain: 'bid by domainCPC',
        suggested: 'Site下建议CPC',
        readChartObj: {
            avgReadTime: '平均阅读时间',
            avgReadRate: '平均阅读率',
            totalReadTime: '总阅读时间',
            readPart: 'READ分布',
            readAnalysis: 'READ分析',
            pv: 'PV数',
            readRate: '阅读率',
            readUserPart: 'READ 用户区',
            tiaoReadPart: '跳读区',
            shuReadPart: '熟读区',
            yueReadPart: '阅读区',
            popInAvgReadRate: 'popIn流入时平均阅读率',
            inAvgReadRate: '一般流入时平均阅读率',
            contentAnalysis: '内容分析',
            clickNumber: '点击次数',
            imp: 'Imp',
            ctr: 'CTR',
            outMediaAnalysis: '外部媒体内容消费模式分析',
            normalReadContent: '平均阅读的内容',
            normalReadTimePeriod: '平时阅读的时间段',
            timePeriod: '时间段',
            category: '类别'
        }
    },
    create: {
        trackingSuffixTip: '网页链接增加该tracking后缀不影响pixel的正常使用。',
        newCountryLang: {
            Japanese: '日语',
            English: '英语',
            Chinese: '中文',
            Thai: '泰语',
            Malay: '马来语',
            Indonesian: '印尼语',
            Korean: '韩语',
            allLanguageSplit: 'All语言拆分具体语言',
            LanguageErrTip: '语言不能为空'
        },
        maxCvTipFirst: '1.初期测试建议活动日预算为5~10倍目标cpa',
        maxCvTipLast: '2.防止MaxCV模式下CPA过高，每天建议最多上调1次日预算，且上调幅度在30%以内',
        dataInheritance: 'Data Inheritance',
        dataInheritanceInternal: 'Data Inheritance（internal）',
        imgUploadSizeUpperLimit: '素材增加上限',
        smartBidmaxcv: 'SmartBid Max CV',
        smartBidmaxcvTip: '如果您希望在预算范围内尽可能获得更多的转化，建议使用此出价策略。',
        submitTitle: '在图片，标题等广告创意里，没有擅自侵害/盗用他人姓名，照片，谈话或肖像，商标，著作物等内容',
        requiredTitle: '(必填)',
        picSize: '图片尺寸',
        displaySize: '【banner素材上传】',
        anySize: '任意尺寸',
        recommendedSize: 'Display广告推荐尺寸',
        plsSelectOne: '请选择【图片尺寸】',
        plsSize: '请上传【{size}】尺寸的图片',
        campaignCategory: '广告类型',
        createCampaignSetps: '创建广告计划',
        campaignCategoryInfo: '为您的广告活动内容定义一个广告类别。若错误选择，将影响系统优化广告的效果，请慎重选择。',
        addAds: '添加广告素材',
        conversionGoal: '转化目标',
        basicSettings: '基础设置',
        campaignTargeting: '广告定向',
        marketingObjective: '推广目标',
        scheduleAndBudget: '排期和预算',
        smartBid: '智能出价',
        smartBidLearning: '智能出价（学习中）',
        smartBidLearningTip: 'MediaGo正在学习如何最好地投放您的广告，广告效果将在学习结束时更上层楼，请避免在学习阶段编辑您的广告。此阶段使用固定出价。',
        smartBidLearningCompleted: '智能出价（学习成功）',
        smartBidLearningCompletedTip: '当前计划已通过学习期探索，智能出价使用中。',
        singleUpload: '逐条上传',
        bulkUpload: '批量上传素材',
        uploadFile: '上传文件',
        uploadCsvFile: '上传CSV文件',
        downloadTemplateCsv: '下载模板文件',
        csvUploadTip: '如您对CSV文件存在疑惑，请下载并参考模板文件。<br>上传文件需删除包含示例的模板文件第三行。<br>输入 URL、广告追踪（可选）、类型、标题、品牌名称、图片、标题。<br>确保 CSV 以 UTF-8 编码保存为 CSV 文件。',
        dragUpload: '请将CSV文件拖放到此处',
        plsUploadCsv: '请上传CSV文件',
        uploadRecord: '	上传记录',
        csvRecordTip: '上传成功的asset数量：所有上传成功的csv文件中的asset数量（最多不超过100个）',
        uploadTime: '上传时间',
        uploadRemark: '备注',
        file: '文件',
        uploadStatus: '上传状态',
        magnitudeEvaluation: '预估量级',
        thirdPartyTracking: '第三方追踪',
        tagType: '标签类型 ',
        eventType: '事件类型',
        url_code: '添加您的链接/代码',
        click: '点击',
        viewImperssion: '可视展示',
        trackingTips: '您可以在此处添加第三方跟踪以捕获您的广告计划数据并将其传送到外部平台以实现您的营销目标。 第三方跟踪支持的宏与 UTM 支持的宏相同。了解更多请注意：使用 JavaScript tag 时，由于部分流量不支持此类跟踪，因此跟踪数据与实际数据可能存在差异',
        jp: '日本',
        au: '澳大利亚',
        gb: '英国',
        sg: '新加坡',
        my: '马来西亚',
        th: '泰国',
        hk: '香港',
        tw: '台湾',
        kr: '韩国',
        id: '印度尼西亚',
        preview: '图片预览',
        collapse: '收起',
        expand: '展开',
        de: '德国',
        fr: '法国',
        it: '意大利',
        es: '西班牙',
        at: '奥地利',
        br: '巴西',
        mx: '墨西哥',
        nl: '荷兰',
        pl: '波兰',
        se: '瑞典',
        be: '比利时',
        pt: '葡萄牙',
        ch: '瑞士',
        cz: '捷克',
        no: '挪威',
        dk: '丹麦',
        hu: '匈牙利',
        deLang: '德语',
        itLang: '意大利语',
        frLang: '法语',
        // campaign
        createCampaign: '排期和预算',
        accountName: '广告账户名称',
        campaign: '广告计划',
        objective: '目标',
        promotionObjective: '推广目标',
        completeConversion: '完成转化',
        purchaseOrder: '购买订单',
        willingnessToInteract: '互动意愿',
        fillForm: '填写表单',
        signUp: '注册会员',
        downLoadData: '下载资料',
        sendMessage: '发送信息',
        browseWebpage: '浏览页面',
        reputation: '知名度',
        enhanceBrand: '提升品牌知名度',
        objectiveTraffic: '网站流量',
        objectiveAwareness: '品牌认知',
        objectiveConversions: '转化',
        objectiveLead: '潜在客户',
        campaignName: '广告计划名称',
        campaignStatus: '投放开关',
        startAndEnd: '开始和结束',
        startTime: '开始时间',
        endTime: '结束时间',
        setTime: '设置时间',
        immediately: '立即开始',
        indefinitely: '长期投放',
        dayparting: '分时段',
        selectDay: '选择您想投放的时间和日期',
        dailySpendCap: '单日预算',
        dailyCapTip: '实际天级花费将低于或高于（最多两倍）单日预算。',
        lifetimeSpendCap: '总预算',
        lifetimeSpendCapTip: '您可以指定每月或活动总预算',
        lifetimeBudget: '总预算',
        monthlyBudget: '月预算',
        pacing: '投放速度',
        // ad set
        search: '搜索...',
        English: '英语',
        Spanish: '西班牙语',
        Japanese: '日语',
        All: '所有',
        lang: '语言',
        countryUsa: '美国',
        countryCanada: '加拿大',
        adSetTitle: '目标设置',
        adSets: '广告组',
        adSetsName: '广告组名称',
        adSetsStatus: '广告组状态',
        adFormat: '广告格式',
        placement: '广告位',
        size: '尺寸',
        homepage: '首页',
        audience: '受众',
        audienceAsiaTip: '注意：所选受众在亚洲地区流量受限',
        audienceAmericaTip: '注意：所选受众在欧美地区流量受限',
        industry: '类型',
        industryPh: '请选择类型',
        industryInfo: `请选择最多两组短语描述上述落地页中的产品或服务类型。精准的描述有利于算法自动为广告计划适配合适的流量。
        * 请勿随意更改类型。`,
        location: '地区',
        state: '州',
        us_state: '州',
        ca_state: '地区',
        au_state: '州',
        gb_state: '地区',
        jp_state: '地区',
        tw_state: '地区',
        id_state: '地区',
        kr_state: '地区',
        hk_state: '地区',
        sg_state: '地区',
        my_state: '地区',
        th_state: '地区',
        // 必须按照缩写_placeholder的格式填写 有多个地方用到
        us_placeholder: '州/邮编',
        ca_placeholder: '地区',
        au_placeholder: '州',
        gb_placeholder: '地区',
        jp_placeholder: '地区',
        tw_placeholder: '地区',
        id_placeholder: '地区',
        kr_placeholder: '地区',
        hk_placeholder: '地区',
        sg_placeholder: '地区',
        my_placeholder: '地区',
        th_placeholder: '地区',
        targetBid: '目标出价',
        contentCategory: '受众类型',
        device: '设备',
        os: '系统',
        osv: 'win10定向投放',
        userPackage: '用户包',
        domainwhitelist: '站点白名单',
        moreBtn: 'more',
        impURL: 'impURL',
        osvTip: '勾选后，你的广告将会被定向投放至Windows 10操作系统。',
        amountSmall: '请注意：你所定向的平台-系统-浏览器组合流量较少，可能会影响广告投放效果，建议尝试其他平台-系统-浏览器组合进行投放。',
        noAmount: '请注意：你所选择的定向系统-浏览器组合没有流量，请重新选择',
        platform: '平台',
        browser: '浏览器',
        gender: '性别',
        carrier: '运营商',
        include: '包含',
        exclude: '排除',
        only: '仅限',
        zipcode: '邮编',
        // 需要有一些失败提醒
        locationTip: '请将信息填写完整',
        locationTip1: ' 不能为空',
        locationCheck: '仅限该地区IP',
        locationCheckTip: '勾选后，仅限IP地址在所选地区的用户。',
        // ad
        allSelect: '全选',
        allSelect1: '全选',
        createAds: '素材',
        adName: '广告名称',
        adAssetName: '素材名称',
        adStatus: '广告状态',
        brandName: '品牌名称',
        headline: '标题',
        asset: '图片素材及名称',
        webUrl: '网页链接',
        upload: '上传',
        reUpload: '重新上传',
        utmSuffix: '广告追踪',
        urlPreview: '组合预览',
        // imageDescription: '为提升点击率，推荐上传比例为16:9、且图片素材居中（与边框保持适当间距）的图片。图片最大为2MB，分辨率需大于600x400像素。',
        imageDescription: '图片最大为2MB，最小分辨率：600x400像素。',
        // CAMPAIGN_ID: '广告计划id',
        // AD_ID: '广告id',
        // AD_NAME: '广告名字',
        // PAGE_TYPE: '页面类型，DHP，HP等',
        // AD_STYLE: '广告样式，infopane，river等',
        // REQ_ID: '请求id，rid',
        // BID_TIME_STAMP: '广告填充时间戳（精度，以go支持的精度为主）',
        // TRACKING_ID: '跟踪id',
        // CPC: '此次广告的CPC'
        more: '查看详情',
        utmInfos: '输入UTM代码进行广告活动追踪。',
        titleInfo: '你可以在标题中设置动态宏',
        titleTemplate1: '该值将被替换为用户正在浏览的地区（包括州/省）',
        titleTemplate2: '这个宏会被替换成月份，比如：January',
        utmInfoHeader: '推荐追踪代码',
        titleInfoHeader: '例如:',
        assetPreview: '素材预览',
        adList: '广告列表',
        adHead: {
            id: '广告Id',
            name: '素材名称',
            image: '图片',
            title: '标题',
            brandName: '品牌名称',
            url: '网页链接',
            searchPlaceholder: '标题/素材名称'
        },
        assetAditionInfo: '更多流量接入中...',
        closedText: '敬请期待...',
        delAdMsg: '确认后，旧的Ad将被归档，但数据会被保留。确认吗？',
        mdyAdMsg: '确认后，旧的Ad将被归档，但数据会被保留。确认吗？',
        disableSizeMsg: '我们建议您保留全部的素材尺寸，我们的算法会从所有的尺寸中挑选出表现最好的进行优化。',
        importZipCode: '导入邮编',
        importDomainList: '导入域名白名单',
        selectCsv: '选择一个CSV文件',
        downloadTemplate: '下载模版',
        previewTip: '内容推荐有多种形式和大小。最终的展示位置和广告素材排列（图片尺寸，标题和品牌名称）取决于它们被展示的媒体平台。',
        domainTip: '你可以通过CSV文件上传所需要投放的domain白名单，但需要注意的是这些白名单只有与平台现存domain匹配才能生效，且匹配成功的白名单只对mobile定向流量生效',
        fixedCpc: '固定CPC',
        notCut: '广告创意图片不切图',
        notCutTip: '如不勾选，系统将按需按比例自动裁剪图片。提交后，不可修改，请谨慎选择。',
        OCPC: 'OCPC',
        ECPC: 'ECPC',
        ocpcTip: '出价将根据转化目标进行动态调整。 选择此功能后，分域名竞价功能将无效。[支持地区:日本]',
        cpaMaxChangeTip: '输入CPA的最大调节系数。为更好的优化CPA, 系统在学习周期中也有可能超过上限。',
        ecpcTip: '系统会在CPC*出价调节系数范围内动态调整出价，以获得更好的转化效果。选择此功能后，分域名竞价的CPC 将随此 CPC 的变动调整而改变。[支持地区:美国,加拿大,英国,澳大利亚]',
        cpaTypeTip: '为使系统获得更好的智能学习，CPA类型一旦选择后，建议不要修改。',
        fisrtStepCpc: '第一阶段CPC',
        secondStepTargetCpa: '第二阶段目标CPA',
        secondStepTargetMcpa: '第二阶段目标MCPA',
        cpaMaxChange: 'CPA调节系数上限',
        cpcChange: '出价调节系数范围',
        cpaType: 'CPA类型',
        targetCpa: '目标CPA',
        targetCpaRequired: '请填写目标CPA',
        targetCpanumbered: '目标CPA必须是数字',
        bidType: '出价方式',
        fixedBid: '固定出价',
        smartBidText: '智能出价',
        smartBidTargetCPA: 'SmartBid Target CPA',
        smartBidTargetCPATip: 'Target CPA设置较低，将无法进入智能出价。',
        smartBidNext: 'Smart Bid',
        smartBid: '智能出价（目前仅支持日本&台湾ip地区）',
        phaseCpc: '第一阶段固定CPC',
        smartBidTips: '*第一阶段-模型学习：固定CPC竞价，积累转化数据\n*第二阶段-智能出价：模型根据推广目标和CPA以及转化数据智能出价',
        smartBidNextTips: '如果您希望系统自动最大化广告活动效果，建议使用此出价策略。',
        smartTargetCpaTips: '目标CPA：埋设popIn CV位置的目标CPA\n系统将根据您填写的目标CPA持续进行投放效果优化。\n请按照您的预期目标CPA进行填写，以免影响系统优化的效果。',
        upToAdsLimitMsg: '该广告计划下 ads 数量最多{limit}条',
        previewImgTip: '图片比例 ',
        previewImgTitle: '您的标题在这里',
        previewImgName: '您的品牌名称在这里',
        theThirdPartyPixelPrompt: '您可以在此处添加第三方跟踪以捕获您的活动数据并将其传送到外部平台以促进您的营销目标。其支持的宏与 UTM的宏一致,',
        PixelPromptMore: '点击了解更多。',
        postback: 'Postback',
        postbackNull: '空值',
        mPostbackNull: '空值',
        postback91App: '91App',
        discovery: 'Discovery',
        mediago: 'MediaGo',
        postbackTip: '该postback设定对广告计划中所有广告统一生效。勾选空值后，将会影响归因准确性',
        assetInfo: '支持多图上传</br>建议宽高比：16:9</br>图片类型：JPG，JPEG，PNG，GIF</br>最大图片大小：5 MB<br>建议产品/logo等重要信息内缩20%，不要太过接近图片边缘',
        assetInfoTip: '图像大小：不超过5MB',
        gifSizeTip: '；GIF大小：不超过10MB',
        assetSingleInfo: '建议宽高比：16:9</br>图片类型：JPG，JPEG，PNG，GIF</br>最大图片大小：5 MB<br>建议产品/logo等重要信息内缩20%，不要太过接近图片边缘',
        createCampaignBtnText: '提交广告计划',
        campaignNoAssetsTips: '该Campaign尚未上传素材',
        addAssetsBtnText: '添加素材'
    },
    audit: {
        review: '审核',
        auditStatus: '审核状态',
        detail: '详情',
        reAudit: '重新审核',
        reviewAds: '审核广告',
        status: '状态',
        inReview: '等待审核',
        limited: '投放受限',
        preliminaryReview: '等待初审',
        secondReview: '等待复审',
        rejected: '未通过',
        rejectedSelect: '部分广告被拒绝',
        manuallyApproved: '人审通过',
        bdApproved: '百度审核员审核通过',
        msApproved: '微软审核员审核通过',
        bdRejected: '百度审核员审核不通过',
        msRejected: '微软审核员审核不通过',
        adId: '广告ID',
        campaignName: '广告计划名称',
        accountName: '账户名称',
        submittedTime: '提交时间',
        startTime: '投放时间',
        adName: '广告名称',
        operate: '操作',
        startReviewing: '开始审核',
        searchPlaceholder: '搜索广告主或广告名称',
        adInfo: '广告信息',
        userId: '用户ID',
        reviewItems: '审核内容',
        pass: '通过',
        fail: '不通过',
        reviewResult: '审核结果',
        conclusion: '结论',
        approved: '审核通过',
        abnormal: '状态异常',
        description: '描述',
        comments: '建议',
        skip: '下一条/跳过',
        submitAndNext: '提交并继续',
        reviewer: '审核人',
        reviewTime: '审核时间',
        reviewed: '已审核',
        reviewedLines: '已审核: {num}条',
        team: '审核团队',
        result: '审核结果',
        reason: '拒审原因'
    },
    geoEdge: {
        type: '触发类型',
        scanTool: '扫描工具',
        time: '触发时间',
        detail: '详情分类',
        detailLink: '详情链接',
        openLink: '打开链接'
    },
    auditEn: {
        isIncludeTestAccount: '包含测试账号',
        geoEdgeDetail: '落地页扫描工具详情',
        scanTool: '扫描工具',
        confirmation: '确认',
        searchSimilar: '批量审核',
        assetType: '素材类型',
        nativeAsset: 'Native',
        displayAsset: 'Display',
        mediaType: '媒体',
        location: '地区',
        language: '语言',
        isGif: '是否gif图',
        languageTip: '英语素材不能跑西班牙语',
        audience: '受众',
        industry: '类型',
        startTime: '开始时间',
        brandName: '品牌名称',
        headline: '标题',
        asset: '图片',
        landingPage: '网页链接',
        webUrl: '网页链接',
        mApproved: '机审中',
        preliminaryReview: '初审',
        secondReview: '复审',
        rejected: '拒绝',
        manuallyApproved: '人工审核通过',
        bdApproved: '百度审核通过',
        msApproved: '媒体审核通过',
        bdRejected: '百度拒审',
        msRejected: '媒体拒审',
        operate: '操作',
        startReviewing: '开始审核',
        searchPlaceholder: '广告计划/账户/审核员',
        adId: '广告ID',
        assetId: '广告系列ID',
        assetName: '广告系列名称',
        campaignName: '广告计划名称',
        accountName: '账户名称',
        submittedTime: '提交时间',
        assetSubmissionTime: '素材提交时间',
        campaignSubmissionTime: '广告计划提交时间',
        reviewItems: '审核对象',
        pass: '通过',
        fail: '拒绝',
        reviewResult: '审核结果',
        conclusion: '结论',
        approved: '通过',
        description: '描述',
        comments: '建议',
        skip: '跳过',
        submitAndNext: '提交并跳转至下一条',
        reviewer: '审核员',
        reviewTime: '审核时间',
        reviewed: '已审核',
        reviewedLines: '已审核：{num}项',
        team: '审核团队',
        result: '审核结果',
        reason: '审核原因',
        status: '状态',
        detail: '详情',
        review: '审核',
        reAudit: '重新审核',
        adInfo: '广告信息',
        adName: '广告名称',
        operateRecord: '审核记录',
        sellerReviewRecords: 'Seller维度审核记录',
        baiduBrainScanRecords: '百度大脑扫描结果',
        refused: '拒绝',
        brainApproved: '通过',
        suspected: '疑似',
        others: '其他媒体',
        modify: '修改',
        baiduBrainScan: '百度大脑扫描',
        baiduBrainScanResult: '百度大脑审核结果',
        baiduBrainRejectReason: '百度大脑审核原因',
        baiduBrainReviewTag: '百度大脑审核标签',
        noData: '无数据',
        lowLevelPornographic: '低危色情；',
        highLevelPornographic: '高危色情；',
        lowLevelPolitics: '低危政治；',
        highLevelPolitics: '高危政治；',
        lowLevelViolenceAndTerrorism: '低危暴恐；',
        highLevelViolenceAndTerrorism: '高危暴恐；',
        lowLevelProhibited: '低危违禁；',
        highLevelProhibited: '高危违禁；',
        lowLevelDisgustingPicture: '低危恶心；',
        highLevelDisgustingPicture: '高危恶心；',
        submit: '提交',
        cancel: '取消',
        confirm: '确认',
        skipInfo: '你将丢失内容，是否确定退出？',
        leaveInfo: '你将丢失内容，是否确定退出？',
        reviewNotComplete: '审核未完成',
        requestError: '网络错误，请重试',
        discarded: '存档',
        pass1next: '审核通过跳转至下一条',
        passAction: '通过',
        geoEdge: 'Geo Edge',
        displayAllImg: '一次性展示所有审核图片',
        setSensitive: '敏感分类',
        baiduBrain: '百度大脑'
    },
    creditLine: {
        currencyConverterTip: '以充值当月的前月份的日度平均汇率进行换算。汇率以The Federal Reserve System为准<a>http://www.federalreserve.gov/releases/h10/hist/default.htm</a>',
        tradingCurrencies: '交易币种',
        accountName: '账户名称',
        accountId: '账户ID',
        status: '账户状态',
        companyName: '公司名称',
        availableBalance: '总可用额度',
        availableBalanceRemark: '总可用额度=帐户余额+Credit Line+Coupon总消耗+有效Coupon总余额（交易币种）',
        balance: '帐户余额',
        balanceRemark: '账户余额=累计充值-累计花费（cpc）（交易币种）',
        totalRecharge: '累计充值',
        totalRechargeRemark: '历史充值金额累计',
        creditLinetotalRechargeRemark: '历史充值金额累计（交易币种）',
        totalSpend: '累计花费',
        untaxedCopy: '累计花费为未含税金额，可能与invoice中累计含税花费金额有差异',
        totalSpendRemark: '历史花费金额累计（交易币种）',
        creditBalance: '信用余额',
        creditBalanceRemark: '当账户余额+Coupon总消耗>=0，信用余额=Credit Line;当账户余额+Coupon总消耗<0, 信用余额=Credit Line + 帐户余额+Coupon总消耗（交易币种）',
        creditLine: 'Credit Line',
        creditLineRemark: '我们给账户设置的可额外使用的总信用额度（交易币种）',
        balanceNote: '当前账户总可用额度小于$200，请注意',
        balanceNote1: '为保证广告不受影响，请尽快充值',
        filter: '筛选',
        charge: '充值',
        edit: '编辑',
        editTitle: '设置Credit Line',
        chargeTitle: '充值',
        currencyTitle: '结算币种',
        tradingCurrencyLable: '交易币种',
        contractualTitle: '合同主体',
        agent: '代理商',
        userName: '操作人',
        note: '备注',
        operationTime: '操作时间',
        amount: '充值金额',
        chargeTime: '充值时间',
        editRecordTitle: 'Credit Line修改记录',
        chargeRecordTitle: '充值记录',
        opt: '操作',
        couponBalance: '有效Coupon总余额',
        couponBalanceRemark: '有效Coupon余额的累加（交易币种）',
        couponSpend: 'Coupon总消耗',
        couponSpendRemark: 'Coupon已消耗累加（交易币种）',
        couponTitle: 'Coupon',
        couponAmount: 'Coupon金额',
        effectiveTime: '生效时间',
        expireTime: '过期时间',
        offline: '下线',
        offlineTip: '确认后该Coupon将永久下线，无法恢复，确认吗？',
        profitRate: '利润率（预期）',
        profitRateRemark: '手动设置的期望该账户达到的利润率。<br/>该利润率会影响实际出价。',
        profitRateTitle: '设置利润率',
        profitRateRecordTitle: '利润率修改记录',
        curProfitRate: '当前利润率',
        transactionCurrency: '交易币种',
        settletmentCurrency: '结算币种',
        couponCost: 'coupon花费',
        adjustmentAmount: '变动金额',
        receivablesWithTax: '应收含税花费',
        adjustmentAmountRecord: '金额变动记录',
        receivablesWithoutTax: '应收未税花费',
        spendPlusTaxRemark: '所选时间内账户累计的未税花费',
        couponCostRemark: '所选时间内账户的Coupon总消耗',
        adjustmentAmountRemark: '所选时间内，账户被调整的总金额（税前）。',
        consumptionTaxRemark: '当期未税花费（调整后）产生的消费税',
        monthlyExchangeRateRemark: '结算月当月平均汇率',
        receivablesWithTaxRemark: '所选时间内，账户累计含税花费。',
        receivablesWithoutTaxRemark: '所选时间内，账户应收未税花费（交易币种）',
        currentCoupons: '当期Coupon消耗',
        selectMonth: '选择时间段',
        amountSetting: '变动金额设置',
        setAmount: '设置变动金额',
        searchText: '搜索账户/公司/代理店名称',
        searchMonthlyText: '公司名称',
        amountRecords: '变动金额修改记录',
        currentUntaxedSpend: '当期未税花费',
        settlementCurrency: '结算币种',
        rebate: '返点金额',
        monthText: '每月3号后不可对以往款项进行修改',
        adjustedText: '请输入完整的需要调整的金额',
        changeRecords: '修改记录',
        remarkNote: '备注',
        changeDate: '变动时间段',
        amountEdit: '变动金额编辑',
        release: '发布'
    },
    roiMgr: {
        serviceType: '服务类型',
        category: '行业分类',
        companyName: '公司名称',
        accountName: '账户名称',
        accountId: '账户ID',
        income: '百度当期收入',
        incomeRemark: '百度当期收入=账户当期消耗-当期Coupon成本',
        spend: '账户当期消耗',
        spendRemark: '所选时间内的账户累计花费',
        cost: '当期流量成本',
        costRemark: '所选时间范围内，我们付给msn的钱（CPM）',
        profit: '当期利润',
        profitRemark: '当期利润=百度当期收入-当期流量成本',
        profitRate: '利润率（实际）',
        profitRateRemark: '利润率=当期利润/百度当期收入',
        impression: '展示数量',
        click: '点击数',
        filter: '筛选',
        coupon: '当期Coupon成本',
        couponRemark: '所选时间内Coupon总消耗',
        rebateRatio: '返点比例',
        rebateRatioRemark: '在ROI报表页面，代理商和旗下广告主均展示返点比例。',
        source: '客户来源',
        sourceCompany: '客户来源公司',
        accountType: '账户性质',
        rebate: '当期返点成本',
        rebateRemark: '当期返点成本 = 百度当期收入 x 返点比例',
        launchDays: '投放天数',
        averageDailySpend: '日均消耗',
        maximumDailySpend: '最高日消耗'
    },
    // 可视化测试
    visualTest: {
        adminEmailCode: '管理员获取邮箱验证码'
    },
    financiaIncome: {
        accountId: '账户ID',
        companyName: '公司名称',
        accountName: '账户名称',
        timezone: '账户时区',
        email: '开户人邮箱',
        submitTime: '创建时间',
        spend: '账户当期消耗',
        spendRemark: '所选时间内的账户累计花费',
        coupon: '当前Coupon发放',
        couponRemark: '所选时间内Coupon总消耗',
        receivablesRemark: '财务应收=账户当期消耗-当期Coupon成本',
        recharge: '当期充值金额',
        receivables: '财务应收',
        businessManager: '商务经理'
    },
    financiaPay: {
        accountType: '账户性质',
        accountId: '账户ID',
        source: '客户来源',
        rebateRatio: '返点比例',
        rebateRatioRemark: '在财务应付页面，代理商下的广告主的返点比例为0（不展示在这里）。',
        companyName: '公司名称',
        accountName: '账户名称',
        timezone: '账户时区',
        email: '开户人邮箱',
        cost: '当期流量成本',
        businessManager: '商务经理',
        costRemark: '所选时间范围内，我们付给msn的钱（CPM）',
        rebate: '当期返点成本',
        sspName: '投放媒体',
        rebateRemark: '当期返点成本（应付）：代理商下的各个广告账户的当期返点成本累加和。<br/>代理商下的广告账户产生的消耗，返点金额应支付给代理商公司本身'
    },
    monthBill: {
        agencyAccountName: '所属代理店',
        monthBillTitle: '月度账单',
        settlementCurrency: '当期结算金额',
        settlementCurrencyRemark: '账户当期含税花费（交易币种）按照月度汇率换汇后的结算币种下金额',
        montlyExchangeRate: '月度汇率',
        settlementSpendWithTax: '当期含税花费',
        spendPlusTax: '当期未税花费',
        consumptionTax: '消费税',
        consumptionTaxRemark: '当期未税花费产生的消费税',
        spendPlusTaxRemark: '所选月份的账户累计未税花费',
        settlementSpendWithTaxRemark: '所选月份的账户累计含税花费，当期含税花费=当期未税花费+当期消费税',
        monthlyExchangeRate: '月度汇率',
        agentName: '所属代理店',
        updataTitile: '前月账单将于次月3日更新',
        monthlyExchangeRateRemark: '月度账单前一月份的日度平均汇率。汇率以The Federal Reserve System为准<a href="http://www.federalreserve.gov/releases/h10/hist/default.htm">http://www.federalreserve.gov/releases/h10/hist/default.htm</a>'
    },
    dataReport: {
        account: '账户',
        device: '设备',
        os: '系统',
        platform: '平台',
        browser: '浏览器',
        audience: '受众',
        industry: '类型',
        location: '地区',
        // country: '国家',
        country: '地区',
        stateregion: '州/地区',
        language: '语言',
        publisher: '媒体',
        campaignName: '广告计划',
        assetName: '素材名称',
        adsize: '广告尺寸',
        spend: '花费',
        viewedImp: '真实展示',
        click: '点击',
        ctr: '点击率',
        colAvgCpc: '平均CPC',
        colVcpm: 'vCPM',
        colDate: '日期',
        conversions: '转化',
        conversionRate: '转化率',
        conversionPrice: '转化价格',
        colConversionPrice: '转化价格',
        colConversionRate: '转化率',
        colCtr: '点击率',
        needAccount: '请选择账户',
        needCampaign: '请选择广告计划',
        timePre: '数据上次更新',
        conversionTimePre: '转化数据上次更新',
        timeAfter: '(报表的时区与您的广告账户的时区一致)',
        targetBid: '目标出价',
        fixedBid: '固定CPC',
        OCPC: 'OCPC',
        ECPC: 'ECPC'
    },
    amDashboard: {
        // 运营配置
        currentSsp: '目前在投SSP',
        // black&white
        kwTitle: '广告账户级别媒体黑、白名单及广告计划级别关键词配置',
        kwSetting: '黑白名单配置',
        kwTag: '关键词配置',
        filterType: '规则类型',
        // blackList: 'Black List',
        // whiteList: 'White List',
        insertList: '导入关键词名单',
        selectCsv: '选择一个csv文件',
        editDate: '修改时间',
        operationId: '修改人',
        objectName: '修改内容',
        insert: '上传',
        edit: '修改',
        importWhite: '导入关键词白名单',
        importBlack: '导入关键词黑名单',
        kwWarning: '以下字段不符合要求请删除后重新上传',
        dialogTitle: '上传结果',
        dialogSubTitle: '共上传{num}个关键词',
        downloadCurrentCSV: '下载当前CSV文件',
        companyName: '公司名称',
        // country: '国家',
        country: '地区',
        language: '语言',
        date: '日期',
        roas: 'ROAS',
        // remainingBudget: '广告计划余额',
        remainingBudget: '活动剩余预算',
        taboolaBind: 'Taboola广告拉取',
        configableCpc: '可设定的CPC下限',
        accountListSetting: '账户列表设置',
        hideClosedAccount: '隐藏已关闭账户',
        hideBalanceAccount: '隐藏总余额小于 0 的账户',
        CPC: 'CPC',
        totalBudget: '总预算',
        dailyBudget: '天预算',
        campaignName: '广告计划名称',
        accountName: '广告主名称',
        status: '投放状态',
        campaignStatus: '投放开关',
        spend: '本期收入',
        impression: '真实展示',
        click: '点击',
        clickRate: '点击率',
        startTime: '开始时间',
        endTime: '结束时间',
        iImpression: '展示数量',
        cost: '成本',
        iCtr: 'iCTR',
        iCpm: 'iCPM (收入)',
        viewableRate: '可视率',
        vCtr: 'vCTR',
        colCfgTitle: '设置列',
        campaignId: '广告计划ID',
        accountId: '账户ID',
        colStatus: '投放状态',
        colStart: '开始时间',
        colEnd: '结束时间',
        colSpend: '本期收入',
        colTotalBudget: '总预算',
        colDailyBudget: '天预算',
        budget: '预算',
        colDetail: '详情',
        colDelivery: 'Delivery',
        colCpc: 'CPC',
        colAvgCpc: '平均CPC',
        colVCpm: 'vCPM (收入)',
        colImp: '真实展示',
        colClick: '点击',
        colCtr: '点击率',
        colConversion: '转化',
        colConversionRate: '转化率',
        colConversionPrice: '转化价格',
        colIimpression: '展示数量',
        colCost: '成本',
        colIctr: 'iCTR',
        colIcpm: 'iCPM (收入)',
        colViewableRate: '可视率',
        blackWhiteConfig: '配置黑白名单',
        colProfitRate: '利润率（实际）',
        colSpendRate: '预算消耗比例',
        colLastSpend: '上期收入',
        colCompareRate: '收入对比',
        colAmResponsible: 'AM负责人',
        spendRemark: '收入对比 =（本期收入 - 上期收入）/上期收入',
        spendRateRemark: '预算消耗比例: 所选时间内该广告的消耗/所选时间内该广告的日预算总和。使用最新的日预算。',
        profitRateRemark: '利润率（实际）=（当期收入 - 当期成本）/ 当期成本',
        totalCampaign: '所有广告计划',
        abnormalCampaign: '异常广告计划',
        campaignOptLog: '广告计划操作日志',
        campaignOptCfg: '广告计划运营配置',
        pageIdBlockCfg: '广告计划PageId黑名单配置',
        campaignAlertCfg: '广告计划异常提醒',
        colIndustry: '投放品类',
        tradeCategory: '行业分类',
        icafe: {
            newItem: '新建事项'
        },
        serviceRecord: '广告主运营记录',
        minCpcRecordText: '出价下限从{old}修改为{new}',
        accountBlackWhiteConfigTitle: '广告账户级别媒体黑、白名单配置',
        campaignBlackWhiteConfigTitle: '广告活动级别黑、白名单配置',
        sellerIdBlackWhiteConfigTitle: '广告账户级别媒体SellerID黑、白名单配置',
        sellerIdBlackWhiteConfigTips: '*需先移除帳戶級別已配置的SSP黑白名單，SellerID層級才會生效',
        rulesType: '规则类型',
        configIndex: '设定层级',
        configObject: '设定对象',
        blackList: '黑名单',
        whiteList: '白名单',
        mediaSiteGroup: '媒体站点组',
        mediaAccountAndSite: '媒体账户/媒体站点',
        mediaAccount: '媒体账户',
        mediaSite: '媒体站点',
        selectedConfig: '当前配置',
        rulesTypeTip: '不允许跨类型添加配置。请先删除并提交当前类型全部配置，再添加新类型配置。',
        deleteAll: '全部删除',
        sitePlaceholder: '支持针对所选媒体账户批量添加媒体站点，输入格式为媒体站点名，使用;分隔。后缀加*走模糊匹配，无后缀走精准匹配。',
        mediaPlaceholder: '支持针对所选媒体账户批量添加账户，输入格式为账户ID，用;号分隔。',
        sellerPlaceholder: '支持批量添加Seller，输入格式为Seller ID，用;号分隔。',
        creativeIdPlaceholder: '多个内容需用两个英文分号分隔',
        cancel: '取消',
        confirm: '提交',
        operationReport: '操作日志',
        operationTime: '操作时间',
        userName: '操作用户',
        actionDetails: '操作记录',
        USA: '美国',
        Canada: '加拿大',
        UnitedKingdom: '英国',
        Australia: '澳大利亚',
        Japan: '日本',
        Korea: '韩国',
        Taiwan: '台湾',
        Indonesia: '印度尼西亚',
        Singapore: '新加坡',
        Malaysia: '马来西亚',
        Thailand: '泰国',
        HongKong: '香港',
        from: '从',
        to: '修改为',
        tips1: '此OCPC配置与创建广告出价中OCPC联动，请谨慎调整。',
        tips2: '1）若Campaign出价为OCPC，广告主可感知，谨慎改动。',
        tips3: '2）若Campaign出价为固定出价，此OCPC配置广告主不可感知。若广告主将固定出价修改为OCPC，此目标CPA将作为OCPC的默认目标CPA。',
        // 通知中心
        notificationCenter: '通知中心',
        createNotification: '发布新通知',
        notificationTitle: '通知标题',
        recipients: '接收人',
        noticeDate: '日期',
        startData: '开始时间',
        endData: '结束时间',
        notificationContent: '通知内容',
        confirmNotice: '确认发送',
        previousNotifications: '已发出通知',
        sender: '发送人',
        submittedTime: '提交时间',
        dataRange: '日期范围',
        notificationStatus: '通知状态',
        changeStatusTip: '状态一旦关闭将无法开启',
        msgTitleRequired: '通知标题不能为空',
        msgRecipientRequired: '请选择接收人',
        msgLanguageRequired: '请选择语言',
        msgContentRequired: '通知内容不能为空',
        msgTipsTitle: '提示',
        msgStatusChangeTips: '状态一旦关闭将无法开启，确定关闭此通知？',
        close: '关闭',
        delete: '已读',
        noMessage: '没有新的通知',
        moreMessage: '更多通知',
        toNoticeList: '返回通知列表',
        noticeDetail: '通知内容',
        closeDetail: '返回',
        noticeListTitle: '通知列表'
    },
    pixel: {
        postback: {
            accountSelectPhd: '无可操作的账户',
            step1: {
                params: '落地页添加追踪参数',
                desc1: '了解详细的参数说明，请参考',
                help: '帮助文档',
                desc2: '',
                suggest: `
                    <div>如广告转化路径如下：</div>
                    <div>点击广告 --> Content Page --> Product Page(广告主网站) --> Thankyou Page (CV转化)。</div>
                    <div style="font-weight:500;">则需输入下列主域名，且所有页面需埋入初始化代码</div>
                    <div style="font-weight:500;">1.Content page主域名</div>
                    <div style="font-weight:500;">2.Product Page主域名</div>
                `,
                phase2Tip: '  (※请注意仅添加初始化代码并未完成转化创建，你仍需要根据下方指引完成创建。)'
            },
            step2: {
                params: 'S2S转化回传'
            },
            importantConversionTip: `
                <div style="line-height:20px;color:#2dd1ac">标记为重要转化，</div>
                <ul style="margin:0;padding-left:20px;color:#2dd1ac;line-height:20px;">
                    <li>该转化会被计入控制台的转化列（CV、CVR、CPA等）</li>
                    <li>算法（如Smartbid等）会根据该转化进行优化；</li>
                </ul>
            `,
            nonImportantConversionTip: `
                <div style="line-height:20px;color:#2dd1ac">标记为非重要转化，</div>
                <ul style="margin:0;padding-left:20px;color:#2dd1ac;line-height:20px;">
                    <li>该转化数据仍然会被记录，你可以在控制台的“设置列“中勾选对应字段查看，但转化列（CV、CVR、CPA等）不会计入这些数据</li>
                    <li>算法（如Smartbid等）不会根据该转化进行优化；</li>
                </ul>
            `,
            step4TipHeader: '请确保初始代码安装在事件代码之前',
            step4TipImg1Header: '如果想跟踪页面加载，请将事件代码片段添加到HTML的<head>元素中',
            step4TipImg2Header: '如果想跟踪内联操作，请将事件代码添加到相关HTML元素中。',
            viewFullCode: '查看完整代码',
            retractFullCode: '收起完整代码',
            conversionDesc1: '当用户点击您设置了跟踪代码的广告时，Discovery将为您的服务器提供一个TRACKING_ID。当发生转化时，您需要通过带有 ${TRACKING_ID} 值的 postback url 发送回转化信息。',
            conversionDesc2: '回发 url 的格式：',
            example: '请注意您需要将参数替换为实际的值，例如：',
            isIncludeConversion: '是否为重要转化',
            lastTime: '最近回传时间',
            postbackReceived: '回传转化次数',
            trackingLP: '落地页URL',
            testTime: '测试时间',
            copyTestUrl: '复制测试链接',
            createTestUrl: '生成测试链接',
            createS2Spostback: '创建S2S postback',
            createCreativesTip: '请务必保留${TRACKING_ID}在您的追踪代码中，否则您的转化将无法被追踪。',
            testCvPostback: '测试转化回传',
            pleaseSelectAcc: '请先选择正确的账户名称',
            testCvEvent: '测试转化行为',
            postbackList: '回传转化列表',
            lpUrlParams: '带追踪参数的落地页URL',
            testUrl: '测试链接',
            cvPostback: '回传转化',
            copyToBrowser: '复制测试链接至浏览器中',
            addCartTip: '在落地页中完成一次您设定的转化事件，如加购、注册、安装应用等',
            checkLpLink1: '未检测到${TRACKING_ID}，为了跟踪您的转化，您必须将${TRACKING_ID}加入到您的落地页URL中，参阅',
            checkLpLinkHelp: '帮助中心',
            checkLpLink2: '了解添加方式。',
            copySuccess: '复制成功，请粘贴至浏览器中。',
            copyError: '复制失败，请稍后重试。',
            getCvPostbackSuccess: '生成成功，请复制测试链接。',
            getCvPostbackError: '生成失败，请稍后重试。',
            lpUrlParamsTip: '添加广告素材 复制 落地页URL 或 编辑广告计划 复制落地页URL',
            tckTitle: '输入UTM代码进行广告活动追踪，例如：',
            campaignName: '${CAMPAIGN_NAME}，活动名称',
            adTitle: '${AD_TITLE}，广告标题',
            contentName: '${CONTENT_NAME}，此广告的关键字',
            tckId: '${TRACKING_ID}，广告追踪ID',
            utmInfoHeaderTip: '请务必保留在您的追踪代码中，否则您的转化将无法通过S2S postback追踪。',
            moreParams: '了解更多UTM参数',
            linkTckPreview: '带追踪参数的<br>落地页链接',
            linkTckPreviewTip: '链接可复制用于测试S2S postback是否正常回传转化。',
            copyLink: '复制链接',
            copyLinkTip1: '复制成功，请至',
            copyLinkTip2: 'S2S postback页面',
            copyLinkTip3: '测试是否正常回传转化。',
            testCvEventDesc: '请先生成测试链接，并在测试链接页面中完成一次您设定的转化事件。',
            copyToBrowserDesc: '复制测试连接至浏览器中，在落地页中完成一次您设定的转化事件，如加购、注册、安装应用等。',
            cvType: '转化类型',
            testCvPostbackDesc: '完成转化后，您需要通过带有 ${TRACKING_ID} 值的 postback url 发送回转化信息。',
            testRecordTable: '测试记录列表',
            testRecordTableDesc: '若已收到转化回传，显示 <span style="vertical-align: middle" class="msn-fa msn-fa-postback-green-right"></span>，未收到转化回传，显示 <span style="vertical-align: middle" class="msn-fa msn-fa-postback-gray-right"></span>。可能存在延迟，最多需要30分钟。'
        },
        companyName: '公司名称',
        selectDimensionTip: '请选择账户/公司',
        accountPlaceholder: '请选择相应账户',
        companyPlaceholder: '请选择相应公司',
        pixelCreateTipHeader: '在选定账户/公司下配置初始Pixel、MCV Event Pixel和CV Event Pixel用于转化追踪',
        pixelCreateTipContent: '若仅对应一个账户埋设pixel，请选择账户名称；若同一公司主体下多个账户需要埋设pixel，请选择公司名称，可无需多次埋设代码。',
        createRead1: '请复制以下内容并将其放在 </ body> 标签之前。',
        createRead2: '我们将在安装标签后检查操作，因此请至少在活动开始前 2 个工作日与客户经理联系。',
        createRead3: 'READ嵌入代码',
        getREADCode: '创建READ代码',
        codeType: '代码类型',
        urlBased: 'URL',
        eventBased: '事件',
        type: '类型',
        accountName: '账户名称',
        conversionType: '转化类型',
        pixelName: '初始Pixel代码名称',
        status: '状态',
        includeInTotalConversion: '重要转化',
        lastReceived: '最后接收时间',
        lastModified: '最后修改时间',
        eventName: '事件名称',
        category: '类型',
        optionalVal: '转化价值',
        optionalValAppend: '用于为自己的转化添加转化价格之类的信息。',
        conversionWindow: 'Window系统转化',
        isIncludeTatalConversion: '重要转化',
        isExcludeCampaigns: '从所有广告计划中排除受众',
        urlBasedTip: 'URL 基准: 每次用户访问一个url符合特定条件的网站，就会上报一次',
        pixelReadTip: ' 如广告转换路径为下：<br>点击广告 --> Content Page --> Product Page(广告主网站) --> Thankyou Page (CV转化)。<br>★ 则需输入下列网域，且所有页面需埋入初始化代码<br>1.Content page主域<br>2.Product Page主域<br>',
        eventBasedTip: 'Event 基准：js code埋点统计，每一次用户做了一个特定动作，例如加入购物车等，就会上报一次',
        urlCondition: 'URL条件',
        urlConditionAppend: 'url满足以下条件之一即会上报:',
        conversionProperties: '转化属性',
        conversionName: '转化名',
        conversionNameAppend: '自定义一个唯一的名字，辅助你区分此转化数据',
        conversionCategory: '转化分类',
        conversionCategoryAppend: '选择此转化所属类别',
        importantConversion: '重要转化',
        importantConversionTipTitle: '如果选择是，',
        importantConversionTip1: '控制台等转化统计会计入这部分数据',
        importantConversionTip2: '算法会根据这部分转化数据优化',
        eventCategory: '选择事件类型',
        eventCategoryAppend: '选择需要触发转化的事件',
        embedHtmlCode: '追踪嵌入代码',
        embedHtmlCodeAppend: '将事件添加到对应事件触发时的代码位置',
        pixelInitialCode: 'Pixel初始化代码',
        pixelInitialInfo: 'Phase 2: 将此段代码加入到落地页及中间跳转页的html代码的head标签中。<br>请注意仅添加初始化代码并未完成转化追踪安装，你仍需要点击创建转化按钮、根据指引完成转化追踪安装。',
        copy: '复制',
        create: '创建转化',
        condition: '条件',
        or: 'OR',
        newCondition: '新条件',
        newConditionAppend: '添加url全等或包含条件，计入转化',
        confirmCreate: '创建转化',
        initPixel: '查看MediaGo 初始 Pixel',
        mcvCvOther: 'MCV/CV/其他',
        initPixelTab: '初始Pixel',
        initPixelPopin: '查看Discovery 初始 Pixel',
        viewCode: '查看代码',
        conversionEdit: '编辑转化',
        step1: 'Step 1',
        step1Desc: '添加Pixel初始化代码 ',
        step1Desc1: '(如果已经添加，请跳过).',
        step2: 'Step 2',
        step2Desc: '选择类型',
        step3: 'Step 3',
        step3Desc: '转化属性',
        step4: 'Step 4',
        pixelInitialInfo1: 'Phase 2: 将此段代码加入到落地页及中间跳转页的html代码的head标签中。',
        headerCode: 'Phase 1：请输入落地页及中间跳转页的主域名',
        headerCode1: '（※如果广告路径含有跨不同域名的情形，请以"换行"的方式，输入所有路径的域名）',
        pixelInitialTitle1: 'Phase 1：请输入落地页及中间跳转页的主域名<br />※如果广告路径含有跨不同域名的情形，请以"换行"的方式，输入所有路径的域名）',
        expandCode: '展开创建并复制初始化代码',
        // new pixel conversions
        createNewPixel: '创建新的Pixel',
        howInstallPixel: '如何安装Pixel？',
        downloadDoc: '下载文档',
        pixelCreateSubTitle: '在选定账户下配置初始Pixel、MCV Event Pixel和CV Event Pixel用于转化追踪',
        selectAccount: '选择账户',
        configInitPixel: '配置初始Pixel',
        addPixelCode: '添加代码',
        mcvEventPixel: 'MCV Event Pixel',
        hasConfigText: '已配置：',
        pixelNumUnit: '条',
        cvEventPixel: 'CV Event Pixel',
        otherEventPixel: '其它Event pixel',
        addInitialPixel: '添加初始Pixel',
        initCodeTips: '*请确保初始Pixel准确安装在广告链路全部页面html代码的head标签中。请在输入框内输入域名，如页面存在跨域，请以“换行”方式输入所有主域名。',
        pixelCodeTips: '*请确保初始Pixel准确安装在广告链路全部页面html代码的head标签中。',
        pixelPlaceholder: '如广告链路LP1、LP2和OP的主域名分别为a.com、b.com和c.com，请以如下方式输入\na.com\nb.com\nc.com',
        getInitialPixel: '生成初始Pixel',
        editUpdateText: '编辑修改',
        initPixelDone: '我已完成初始Pixel代码添加',
        saveAndCopy: '复制并保存',
        setMcvEventPixel: '配置MCV Event Pixel',
        mcvEventTips: '*请确保MCV Event Pixel准确安装在第一个落地页的下一个页面html代码的head标签中。',
        getMcvEventPixel: '生成MCV Event Pixel',
        copyMcvPixelTips: '*请将本段代码安装在第一个落地页的下一个页面html代码的head标签中。',
        mcvPixelDone: '我已完成MCV Event Pixel代码添加',
        setCvEventPixel: '配置CV Event Pixel',
        cvEventCodeTips: '*请确保CV Event Pixel准确安装在推广目标完成的页面或按钮上。',
        getCvEventPixel: '生成CV Event Pixel',
        copyCvPixelTips: '*请将本段代码安装在推广目标完成的页面或按钮上。',
        cvPixelDone: '我已完成CV Event Pixel代码添加',
        setOtherEventPixel: '配置其它Event pixel',
        otherEventTips: '*请确保Event Pixel准确安装在对应事件触发时的代码位置。',
        copyOtherPixelTips: '*请将本段代码安装在对应事件触发时的代码位置。',
        getOtherEventPixel: '生成其它Event pixel',
        otherPixelDone: '我已完成其它Event pixel代码添加',
        trackingCodeTips: '*追踪嵌入代码，将事件添加到对应事件触发时的代码位置',
        manageExistPixel: '管理已有Pixel',
        initPixelTips: '请将初始Pixel安装在广告链路全部页面html代码的head标签中。',
        mcvPixelTips: '请将MCV Event Pixel安装在Landing Page 2 html代码的head标签中。',
        cvPixelTips: '请将CV Event Pixel安装在推广目标完成的页面或按钮上。',
        hasDeprecated: '（已弃用）'
    },
    // 权限部分
    permissionMgr: {
        accountId: '账户id',
        accountStatus: '账户状态',
        accountName: '账户名',
        companyName: '公司名',
        type: '公司性质',
        email: '开户人邮箱',
        payMethod: '支付方式',
        adMethod: '投放方式',
        timezone: '时区',
        createAccount: '新建账户',
        inviteLink: '开户邀请链接',
        copyLink: '复制邀请链接',
        dailyCmpCount: '账户API单日创建campaign上限',
        dailyCmpCount2: '',
        openDailyCmpCount: '开放账户API单日创建campaign上限',
        dailyLimit: '当开启时，账户使用API单日可创建500条Campaign，单条CPN下最多创建10个asset。',
        roleMgr: {
            detail: '展开详情',
            edit: '权限管理',
            del: '删除'
        }
    },
    permission: {
        notAuthorizeTip: '非Admin角色不可被授权该功能',
        p1: '请点击同意MediaGo的隐私政策和服务条款以继续。',
        password: '密码',
        newPassword: '新密码',
        timeZone: '时区',
        inputVCode: '验证',
        vCode: '发送验证码',
        forgotPasswordInputVCode: '验证码',
        forgotPasswordVCode: '发送验证码',
        title: '广告主开户',
        subtitle: '以下是您的用户信息。还有2步，即可进入。',
        userId: '用户id',
        accountType: '账户类型',
        quizz1: '1. 请问您所在的公司是否已经开户？',
        yes: '已开户',
        no: '未开户',
        quizzyes: '2. 请点击“发送邮件”按钮，进行权限申请。',
        quizzno: '2. 请点击“发送邮件”按钮，进行开户和权限申请',
        sendEmail: '发送邮件',
        email: {
            title: '【用户申请表】',
            divider: '===============================================================',
            title1: '申请权限 + 开户',
            bodyTitle1: 'MediaGo MSN申请权限',
            bodySubtitle1: '(注意请补充完整空白字段)',
            bodyTitle2: 'MediaGo MSN申请广告账户并赋予权限',
            bodySubtitle2: '(注意请补充完整空白字段)',
            account: {
                title: '【开户表】',
                company: '* 公司主体名称: ',
                registerAddr: '* 公司注册地址: ',
                operationAddr: '* 公司经营地址: ',
                type: '* 公司性质: ',
                officialSite: '* 官网链接: ',
                position: '* 开户人职位:',
                owner: '* 开户人信息:',
                ownerInfo: '(请输入您的邮箱)',
                accountNum: '* 开户数量:    1个 ',
                accountNumInfo: '(您只能申请1个账户。)',
                accoutName: '* 账户名称:  ',
                totalCap: '* 账户总预算:    $ ',
                totalCapInfo: '(单位: 美元)',
                chargeType: '* 支付方式:  ',
                chargeTypeInfo: '(请输入“预付/到付”)',
                manner: '* 投放方式:   ',
                mannerInfo: '(请输入“自投/代投”)'
            },
            permission: {
                title: '【用户申请表】',
                name: '* 昵称:',
                nameInfo: '(请输入真实姓名-职位。如：段莹-PM)',
                userId: '* 用户id: ',
                accountType: '* 账号类型: ',
                userType: '* 用户类型:  ',
                userTypeInfo: '(请输入：百度员工/广告主/代理商) ',
                email: '* 邮箱: ',
                emailInfo: '(请输入您的邮箱)',
                company: '* 公司主体名称: ',
                adAccount: '* 广告账户名称: ',
                adAccountInfo: '(请输入您公司所开账户的名称。) '
            },
            result: {
                info: '感谢您的申请，',
                info1: '我们会尽快联系您。',
                footer1: '如有其他问题，请联系:',
                footer2: '我们会尽快回复'
            }
        },
        emailReport: {
            create: '创建报告',
            status: '状态',
            acountName: '账户名称',
            timeZone: '报告时区',
            recivieName: '收件人',
            frequency: '发送频率',
            closeTips: '关闭后就不再发送',
            zoneTips: '与账户时区一致',
            emailFormat: '可输入多个邮箱，用逗号隔开',
            day: '每日',
            month: '每月'
        },
        formTitle: '注册MediaGo账号',
        formDesc: '填写以下信息，我们会尽快联系您帮您开放平台权限。',
        formAppend: '点击申请，你将同意MediaGo的',
        formAppendLink: '隐私协议',
        // country: '国家',
        country: '地区',
        currency: '结算币种',
        firstName: '名字',
        lastName: '姓氏',
        formEmail: '工作邮箱',
        formEmailTip: '该邮箱将被用做用户名',
        submitProtocol: '是的！我愿意收到定制化的广告优化建议',
        timeZoneTip: '选择一个你的投放时区，确定后将无法修改',
        currencyTip: '选择你的结算币种，选定后不可修改',
        organizationName: '公司名称',
        companySite: '公司网址',
        jobTitle: '职位',
        phone: '电话',
        companyNameandtips: '公司注册名称（注册时填写）',
        apply: '立即开始',
        createAccount: '创建',
        tologinText: '已有{platform}账号'
    },
    openAccount: {
        stepOne: '广告账户申请',
        stepTwo: '绑定用户',
        stepThree: '申请完成',
        workEmailTip: ' (该邮箱是您在MediaGo账号的用户名）',
        billingEmail: '财务结算邮箱',
        billingEmailSymbol: '请使用英文\';\'分割符号',
        billingEmailLength: '长度不能超过1000个字符',
        billingEmailTip: '若有固定接受账单的邮箱，请填写至该输入框，邮箱之间用 ; 隔开。',
        accountTitle: '广告账户开户申请表',
        accountTitleDesc: '以下信息仅用于开户申请，我们不会将您的信息泄露出去',
        accountTitleDesc1: '您提供的信息将仅用于账号申请。',
        currency: '结算币种',
        // 币种提示
        currencyTitle: '选择你的结算币种，选定后不可修改',
        // 交易币种提示
        tradingCurrencyTitle: '选择你的交易币种，选定后不可修改。',
        // 合同提示
        contractualTip: '请输入合同主体',
        accountTitleDesc2: '由于您填写的信息将直接影响结算，请填写与官方登记备案文件中一致的公司信息。',
        accountName: '账户名称',
        accountEmail: '开户人邮箱',
        totalBudget: '账户总预算',
        payMethod: '支付方式',
        prepayment: '预付',
        delivery: '到付',
        timezone: '账户时区',
        adMethod: '投放方式',
        selfService: '自投',
        managed: '代投',
        companyName: '公司主体名称',
        registerAddr: '公司注册地址',
        operateAddr: '公司经营地址',
        website: '官网链接',
        userTitle: '平台用户绑定',
        userTitle1: '广告用户申请',
        userTitleDesc: '一个广告账户可绑定多个用户，填写以下信息添加或注册MediaGo账号',
        addExistAccount: '添加已有账号',
        registAccount: '注册新账号',
        // country: '国家',
        country: '地区',
        firstName: '名字',
        lastName: '姓氏',
        email: '工作邮箱',
        jobTitle: '职位',
        phone: '电话号码',
        submitAppend: '点击申请，你将同意MediaGo的',
        privacyLink: '隐私协议',
        terms: '条款',
        logout: '退出',
        del: '删除',
        add: '添加',
        apply: '申请',
        formAppendLink: '隐私协议',
        info: '感谢您的申请，',
        info1: '我们会尽快联系您。',
        footer1: '如有其他问题，请联系:',
        footer2: '我们会尽快回复',
        invalidToken: '此链接无效，请联系你的AM重新获取链接',
        inviteMsg: '邀请你在MediaGo开户'
    },
    validations: {
        campaignObjective: '推广目标不能为空',
        noticeTitleTip: '通知标题不能为空',
        couponAmount: '请输入需要设置的Coupon金额',
        effectiveTime: '请选择生效时间',
        validNumber: '请输入有效数字',
        validDecimal: '只能保留3位小数点',
        simpleRequired: '必填',
        jobTitle: '请输入有效的职位名称',
        firstName: '请输入有效的名',
        lastName: '请输入有效的姓',
        required: '$field不能为空',
        number: '请输入有效数字',
        money: '请输入有效金额',
        moneyNum: '目标出价必须是数字',
        moneyJpy: '单日预算必须为整数',
        moneyNumJpy: '目标出价必须是整数',
        moneyTotalJpy: '总预算必须为整数',
        numberBeyond: '数字不能大于1,000,000,000',
        numberBeyondJpy: '单日预算不能少于￥2,000/天',
        dailyCapBeyond: '单日预算不能少于{money}/天',
        cpcBeyond: '为优化广告效果，目标出价不能少于{money}',
        checkCurrencyMaxCPC: 'CPC出价不得高于{money}',
        // numberBeyondJpy: '单日预算不能少于￥2,000/天',
        numberBeyond1: '数字不能大于1,000,000',
        dailyOverTotal: '单日预算不能超过总预算',
        dailyOverTotal2: '单日预算不能超过总预算',
        dailyOverTotal3: '总预算不能小于单日预算',
        dailyOverTotal4: '单日预算不能少于$20/天',
        dailyOver20: '单日预算不能少于$20/天',
        lifetiemUnderDaily: '总预算不能小于单日预算',
        targetBidOverDaily: '目标出价不能超过单日预算',
        targetBidOverDailyJpy: '目标出价必须为整数',
        targetBidOverDaily2: '目标出价不能超过单日预算',
        startAfterEnd: '起始时间不能晚于结束时间',
        start: '起始时间不能为空',
        end: '结束时间不能为空',
        endBeforeStart: '结束时间不能早于开始时间或当前时间',
        status: '状态不能为空',
        daily: '单日预算不能为空',
        minLimit: '单日预算不能少于￥2,000/天',
        lifetime: '总预算不能为空',
        targetBid: '目标出价不能为空',
        // create
        image: '请上传一张合适的图片',
        imageRevise: '请重新上传合适的图片',
        imageInfo: '请上传一张{scale}px的图片',
        size: '尺寸不能为空',
        http: '请输入带有"http://"或者"https://"协议的有效链接',
        charLenth: '不能超过250个字符',
        charLen: '不能超过{num}个字符',
        revise: '请修改审核不通过的值',
        name: '名称不能为空',
        platform: '平台不能为空',
        os: '系统不能为空',
        browser: '浏览器不能为空',
        adFormat: '广告格式不能为空',
        placement: '广告位不能为空',
        audience: '受众不能为空',
        industry: '类型不能为空',
        location: '州不能为空',
        zipcode: '邮编不能为空',
        brandName: '品牌名不能为空',
        headline: '标题不能为空',
        adAssetName: '素材名称不能为空',
        imageValidations: '图片素材不能为空',
        adAssetNameNotRepeat: '素材名称不能重复',
        landingPage: '网页链接不能为空',
        impURL: '第三方追踪链接不能为空',
        landingPageError: '网页链接中不能包含空格 ，以及多个？',
        noAds: '没有可以审核的广告',
        skipInfo: '跳过后，内容会丢失。确认跳过？',
        leaveInfo: '离开后，内容会丢失。确认离开？',
        reviewNotComplete: '未审核完成',
        advertiser: '请选择一个广告账户',
        utmParams: '广告追踪不能包含?, @, /, 空格以及换行符',
        picDuplicated: '不要上传相同的图片',
        // 登录
        email: '请输入有效的邮箱地址',
        organizationName: '请输入组织名称',
        companySite: '请输入公司网址',
        phone: '请输入电话号码',
        switchLang: '切换语言会导致填写的数据丢失，确定切换吗？',
        confirmToSwitch: '您有尚有未保存的编辑内容，是否保存更改以继续？',
        confirmTip: '发布操作不可取消，请点击确认按钮以继续。',
        saveTip: '是否保存当前操作？请点击相应按钮以继续。',
        cacheNewCampaign: '你要继续编辑之前的广告计划吗？',
        cacheContinue: '继续编辑',
        cacheAgain: '重新开始',
        cacheSaveNewCampaign: '离开当前页面后，系统会保留已编辑的内容，可在下次新建广告时继续编辑。',
        fileParse: '文件解析中，请点击提交按钮。5-15分钟后于新控制台查看广告是否创建成功。',
        pwd: '密码至少为8位，需包含数字、字母和符号',
        pwdNotMatch: '两次输入的密码不一致',
        relation: '请选择过滤大小关系',
        pwdRequired: '请输入有效的密码',
        // open account
        company: '请输入正确的公司名',
        companyName: '请输入正确的公司名',
        account: '请输入账户名称',
        budget: '请输入预算',
        registerAdd: '请输入正确的公司注册地址',
        registerAddress: '请输入正确的公司注册地址',
        businessAddress: '请输入正确的公司运营地址',
        timezone: '请选择账户时区',
        // country: '请选择国家',
        country: '请选择地区',
        payMethod: '请选择支付方式',
        adMethod: '请选择投放方式',
        vCodeNeed: '请输入正确的验证码',
        // 权限
        noUser: '没有可以授权的用户',
        errorCampaign: '无权限的操作',
        errorUploadImgSize: '上传图片大小不能超过 500K!',
        blackWhiteType: '请选择黑名单或白名单',
        sspRequire: '请选择媒体账户',
        // pixel
        accountRequired: '请选择账户',
        conversionNameRequired: '请输入转化名',
        categoryRequired: '请输入类型',
        valueRequired: '请输入转化价值',
        eventTypeRequired: '请选择事件类型',
        repeatEmail: '邮箱不能重复',
        emailNotExist: '邮箱不存在',
        selectUser: '请填写需要绑定的用户',
        cpcInfo: '目标出价只能保留3位小数点',
        cpcJpyInfo: '目标出价只能保留1位小数点',
        targetCpaInfo: '目标CPA只能保留2位小数点',
        cpcInfoTarget: '目标出价未更改',
        cpcNumber: '目标出价必须是数字',
        invalidFile: '邮编添加失败，请根据模版上传正确的CSV文件',
        sensitiveTag: '敏感标签不能为空',
        cpcPercentMin: '输入框数值不能低于-99',
        cpcNumberInteger: '请输入整数',
        currencyCheck: '请选择账户结算币种',
        tradingCurrencyCheck: '请选择账户交易币种',
        legalRepresentativeCheck: '请选择以下选项之一',
        contractCheck: '请选择账户合同主体',
        targetCpa: '请输入第二阶段目标CPA',
        targetMax: '仅支持输入整数，且最小输入值为100.',
        cpcMin: '仅支持输入整数，且最小输入值为10.',
        cpcMax: '仅支持输入整数，且最小输入值为100.建议比例上限在150%以上.',
        cpcMaxUp: '上限数值须大于下限数值。',
        cpaType: '请选择CPA类型'
    },
    currencySpecies: {
        dollar: 'USD（$）',
        bahtDollar: 'THB（฿）',
        japaneseDollar: 'JPY（￥）',
        RMB: 'CNY（CNY ￥）',
        taiwanDollar: 'TWD（NT$）',
        HKDollar: 'HKD（HK$）',
        singaporeDollar: 'SGD（S$）',
        wonDollar: 'KRW（₩）',
        malaysianRupiah: 'MYR（RM）',
        indonesianRupiah: 'IDR（Rp）'
    },
    infos: {
        info: '提示',
        total: '总计',
        emptyText: '暂无数据',
        emptyReadChartText: '没有设置READ代码，无法显示数据',
        allSelect: '全选',
        selected: '已选',
        dragTip: '（拖放以重新排序）',
        conversionTip: '请求MediaGo回传链接查看转化数据，{}。或在Pixel菜单下设置MediaGo Pixel，{}。转化数据的更新会有约2个小时的延迟。',
        DiscoveryConversionTip: '请求Discovery回传链接查看转化数据{}。或在Pixel菜单下设置Discovery Pixel，{}。转化数据的更新会有约2个小时的延迟。',
        conversionTipGo: '前往',
        conversionTipMore: '查看详情',
        postBack: '回传链接:',
        sessionInvalid: '系统已更新，请刷新页面重试',
        fileSizeLimit: '为保证广告效果，建议图片分辨率大于600*400像素'
    },
    constants: {
        all: 'All',
        cancel: '取消',
        selectAll: '全选',
        eq: '相等',
        gt: '大于',
        lt: '小于',
        ge: '大于等于',
        le: '小于等于',
        platform: '平台',
        os: '系统',
        browser: '浏览器',
        smartphone: '智能手机',
        mobile: '移动设备',
        desktop: '电脑',
        tablet: '平板',
        xbox: 'Xbox',
        android: '安卓',
        ios: 'iOS',
        macOs: 'Mac OS',
        windows: 'Windows',
        linux: 'Linux',
        chrome: 'Chrome',
        firefox: 'Firefox',
        safari: 'Safari',
        ie: 'Internet Explorer',
        samsung: 'Samsung',
        opera: 'Opera',
        uc: 'UC Browser',
        inApp: 'InApp',
        edge: 'Edge',
        est: 'EST',
        utc0: 'UTC+0',
        utc7: 'UTC+7',
        utc8: 'UTC+8',
        usdname: '美元',
        jpyname: '日元',
        cnyname: '人民币',
        sgdname: '新加坡元',
        myrname: '令吉',
        krwname: '韩元',
        idrname: '印度尼西亚盾',
        thbname: '泰铢',
        twdname: '台币',
        hkdname: '港币',
        utc9: 'UTC+9',
        standard: '匀速',
        accelerate: '加速',
        other: '其他',
        daily: '天',
        hourly: '小时',
        earlier: '早于',
        later: '晚于',
        at: '当天',
        white: '白名单',
        black: '黑名单',
        msn: 'MSN',
        mgid: 'MGID',
        // 合同主体
        mediago: 'mediago',
        jpSubject: 'jp.popin.cc',
        krSubject: 'kr.popin.cc',
        twSubject: 'tw.popin.cc',
        docomo: 'docomo',
        au: 'au',
        softbank: 'softbank',
        male: '男',
        female: '女',
        // 受众
        car: '汽车',
        election: '选举类',
        entertainment: '娱乐类',
        money: '金钱类',
        finance: '金融类',
        foodDrink: '食物和饮品类',
        homepage: '主页类',
        health: '健康类',
        lifestyle: '生活类',
        music: '音乐类',
        movies: '电影类',
        news: '新闻类',
        sports: '运动类',
        travel: '旅行类',
        TV: '电视类',
        video: '视频类',
        weather: '天气类',
        education: '教育类',
        gadgetsAutos: '工具和汽车类',
        business: '商业类',
        beautyFashion: '美容与时尚类',
        economyMoney: '经济与金钱类',
        homeLifestyle: '家居与生活类',
        loveMarriage: '爱情与婚姻类',
        politicsSocial: '政治与社会类',
        travelGourment: '旅游与美食类',
        autoselectronics: '汽车与电子类',
        // 广告分类
        auto: '汽车类',
        beautyFitness: '美容与健身类',
        beautyHairCare: '美容护发类',
        businessAndFinance: '商业和金融类',
        careers: '职业类',
        computerElectronics: '计算机与电子类',
        eCommerce: '电子商务类',
        eventsAndAttractions: '活动和景点类',
        familyAndRelationships: '家庭和关系类',
        financeInsurance: '金融保险类',
        food: '食物类',
        gaming: '游戏类',
        Gaming: '游戏类',
        healthcare: '卫生保健类',
        homeGarden: '家居与园艺类',
        newsAndPolitics: '新闻和政治类',
        pets: '宠物类',
        personalCareOralCare: '个人护理-口腔护理类',
        popCulture: '流行文化类',
        realEstate: '房地产类',
        religionSpiritualty: '宗教与灵性类',
        science: '科学类',
        travelHospitality: '旅居类',
        weightLoss: '减肥类',
        wellness: '健康类',
        others: '其他',
        content: '内容站',
        leadGen: '潜在客户',
        search: '搜索类',
        insurance: '保险类',
        recruitment: '招聘类',
        marriage: '婚姻类',
        medicalIndustry: '医疗行业类',
        diet: '饮食类',
        beautyCosmetic: '美容化妆品类',
        homeAppliances: '家用电器类',
        computer: '计算机类',
        communication: '沟通类',
        media: '媒体类',
        physicalEducation: '体育类',
        beautyCosmetics: '美妆类',
        industry: '类型'
    },
    requestMsg: {
        requestError: '获取数据失败，请稍后再试',
        exportError: '导出数据失败，请稍后再试',
        exportSuccess: '导出成功！',
        statusUpdated: '状态更新成功',
        updatedFailed: '数据更新失败',
        createError: '创建失败',
        uploadFailed: '上传失败，稍后再试!',
        uploadOverSize: '文件不能超过{num}MB',
        uploadSuccess: '上传成功',
        gifSizeInfo: 'gif图片尺寸无效，请重新上传合适的图片',
        logoutSuccess: '登出成功',
        logoutFailed: '登出失败',
        modified: '您的密码已修改成功！',
        loginFailed: '账号或密码错误',
        copySuccess: '复制成功',
        previewFirst: '请先预览'
    },
    backendMsg: {
        fail: '失败',
        success: '成功',
        sendCodeSucs: '发送验证码成功',
        alreadyExist: '该邮箱已被使用，请更换邮箱地址',
        verificateFail: '请输入正确的验证码',
        busy: '服务器繁忙，请稍后再试',
        noPermission: '无权限的操作',
        gifError: 'GIF的尺寸和card不匹配',
        loginError: '用户名或密码错误',
        registError: '邮箱重复',
        repeatCreate: '请不要重复创建广告',
        operateTooMuch: '过于频繁的操作',
        amountError: '金额不能小于0',
        accountExist: '账户已经存在',
        companyExist: '公司已经存在',
        campaignNameExist: '同账户内广告计划名不可重复',
        paramsError: '参数错误',
        addRepeat: '不能重复添加',
        landingpageOrUtmIsInvalid: 'landingPage或者是utm校验失败',
        conversionExist: '转化名已存在',
        requestError: '获取数据失败，请稍后再试',
        linkInvalid: '无效token',
        roleExist: '角色名已存在',
        noResult: '该验证码已超时，或者该邮箱未发送验证码',
        ecommercePageNameRepeated: '页面名称已存在，请输入其他名称',
        ecommerceSeoUrlRepeated: 'SEO URL 已经存在',
        auditTypeNameExist: '审计类型名称已经存在',
        tagExist: '标签已存在',
        readExist: '该Read代码已存在',
        completedDataAccumulation: '本活动已完成数据积累，无法使用一键继承功能',
        sizeTooLarge: '最大图片大小不能超过5MB',
        gifSizeTooLarge10: '最大GIF大小不能超过10MB',
        imgTypeError: '仅支持上传JPG，JPEG，PNG，GIF等图片类型'
    },
    // paypal充值
    paymentList: {
        time: '时间',
        method: '支付方式',
        actualAmount: '实际金额',
        status: '支付状态',
        operate: '详情',
        prepaidAmount: '支付金额',
        id: '支付ID',
        transferFee: '手续费',
        accountName: '广告账户',
        payer: 'Paypal账户',
        receipt: '收据',
        totalCoupon: '有效Coupon总额',
        couponBalance: 'Coupon余额',
        totalQuotaTip: '总可用额度 = 累计充值 + Credit Line + 有效Coupon总额 - 累计花费',
        fold: '收起账户数据',
        expand: '展开所有账户数据',
        recharge: '充值',
        autoBilling: '自动充值',
        balanceLess0: '该账户总可用额度不足，请{充值}后再行创建广告。'
    },
    // stripe支付
    stripeList: {
        status: '状态',
        account: '广告账户',
        method: '支付方式',
        date: '上次支付日期',
        amount: '上次支付金额',
        spend: '账户花费',
        card: '支付卡号',
        ABAmount: '自动扣款金额',
        edit: '编辑',
        paymentDetails: '支付详情',
        history: '支付历史'
    },
    // bidAnalysis
    bidAnalysis: {
        adId: '广告ID',
        placementId: '平台ID',
        avgRank: '排名',
        bidMode: '竞价模式',
        picpm: 'P-ICPM',
        pvcpm: 'P-VCPM',
        pictr: 'P-ICTR',
        pvctr: 'P-VCTR',
        pvwr: 'P-VWR',
        pvctrLevel: 'p-vctr level',
        pvwrLevel: 'p-vwr level',
        cpc: 'CPC',
        campaignId: '广告计划ID',
        title: '广告标题',
        linkTo: '竞价分析'
    },
    // 电商
    'nativeE-commerceTool': {
        createFormAccountName: '账户名称',
        createFormProductName: '产品名称',
        createFormSampleLPUrl: '落地页样本链接',
        createFormParseBtn: '解析',
        createFormPageName: '页面名称',
        createFormPageNameLength: '长度不能超过300个字符',
        createFormImages: '图片',
        createFormImagesBtn: '查看全部图片',
        createFormreplaceKeyword: '替换关键词',
        createFormreplaceKeyword1: '全部替换',
        createFormreplaceKeyword2: '至',
        createFormAddRules: '添加规则',
        createFormMatchCase: '相符',
        createFormCTAURL: 'CTA URL',
        createFormSEOURL: 'SEO URL',
        createFormSeoTagTitle: 'SEO 标题',
        createFormseoDescription: 'SEO 描述',
        createLPBtn: '添加新落地页',
        createOPBtn: '添加新 OP',
        parseSuccess: '解析成功',
        parseFail: '解析失败',
        searchLPFilter: '搜索页面名称',
        searchButton: '搜索',
        productMultipleChoice: '产品只允许单选',
        coppyOPHTML: '复制 HTML',
        productChoice: '请选择产品',
        opCreatedPageName: '请输入页面名称',
        coppyLPLinkButton: '复制链接',
        selectDomain: '选择域名',
        addTemplate: '添加邮件模板',
        searchtemplate: '搜索邮件模板',
        emailTemplateName: '邮件模板名称',
        status: '状态',
        domain: '域名',
        emailAddress: '邮件地址',
        emailPassword: '密码',
        lastModifiedDate: '最后修改日期',
        creationUser: '创建人',
        lastModifiedUser: '最后修改人',
        operation: '操作',
        domainName: '域名名称',
        templateName: '模板名称',
        conditions: '条件',
        storeLogo: '店铺图标',
        storeName: '店铺名称',
        emailTitle: '邮件标题',
        emailContent: '邮件内容',
        emailImage: '邮件图片',
        emailFooter: '脚注',
        addNewEmailTemplate: '添加邮件模板',
        editEmailTemplate: '编辑邮件模板',
        copyEmailTemplate: '复制邮件模板',
        deleteEmailTemplate: '删除邮件模板',
        recommendedSize: '推荐尺寸'
    },
    supplement: {
        blockSiteDataSuffix: '屏蔽site数据后缀',
        blockSiteDataSuffixTip: '当开启时，该账户及其活动屏蔽site数据后缀。',
        bulkUpload: '批量上传素材',
        regionEurope: '地区：德、法、意、西、奥',
        locationIP: '地区IP定向',
        locationTW: 'TW定向MY/HK',
        editCompany: '编辑公司',
        agentAccount: '代理商帐户',
        selectAgentAccount: '请选择代理商帐户',
        externalWebsite: '外网链接填写公司信息',
        change: '修改',
        editAccount: '编辑账户',
        addAccount1: '新增账户',
        pleaseEnterContent: '请输入内容',
        openingInformation: '开户信息',
        agencyPublisher: '若选择账户性质为代理商,则一经选择不可修改',
        specifiedSSP: '指定SSP',
        operationType: '操作类型',
        accountManager1: '账户/负责人',
        progressInDescription: '进展描述',
        descriptionOfCurrentProgress: '当前进展描述',
        currentProgress: '请输入当前进展描述',
        pleaseSelect: '请选择',
        quantityAllotted: '分配数量',
        discoveryOnline: 'discovery线上合同',
        seleced: '已选',
        editRemark: '修改备注',
        bid: '出价',
        noBid: '不出价',
        fixedBid: '固定出价',
        smartBid: '动态出价',
        noAdBid: '没有待审核的广告位出价配置',
        EntNumber: '请输入-10到10之间的数',
        trainPrice: '训练出价',
        floorPrice: '底价',
        maxPrice: '最大出价',
        limitCPC: '最低CPC',
        atLeast: '至少需要1小时或者1天',
        noCreateAPI: '该帐户不满足创建API的条件',
        eventArea: '请选择活动区域',
        pleaseenterrecipientemail: '请填写收件人邮箱',
        billingInformation: '结算信息',
        cooperationMode: '合作模式',
        viewRS: '查看RS修改记录',
        setRS: '设置新的RS',
        onceEnter: '一经填写不可修改',
        billingCycle: '结算周期',
        financialEmail: '财务邮箱',
        sendInvoice: '用于发送invoice信息，可添加多个财务邮箱，用;分隔开',
        mediaInformation: '媒体信息',
        trafficType: '流量类型',
        adTool: '广告工具扫描',
        addUser1: '添加用户',
        userBackground: '用户背景',
        authorizationConfig: '权限配置',
        roleConfig: '角色配置',
        remenberUser: '用户添加成功，请记录以下用户账号密码',
        userName: '用户名称',
        userPassword: '用户密码',
        advertiserLogin: '用户添加成功，请使用广告主平台密码登录',
        done: '完成',
        accountORcompany: '输入账户/公司名',
        selectRole: '请选择角色',
        selectBMaccount: '请选择BM账户',
        selectWritable: '请选择可写账户',
        selectReadable: '请选择可读账户',
        accAuthorization: '账户授权',
        enterAmountCoupon: '请输入要充值的Coupon金额',
        enterBM: '请输入商务经理',
        enterIndustryClassification: '请输入行业分类',
        enterIndustry: '请输入具体行业',
        selectAM: '请选择AM负责人',
        selectContractType: '请选择合同主体',
        selectAccountLanguage: '请选择账户语言',
        selectCompanyType: '请选择公司类型',
        addressCompany: '请输入公司运营地址',
        enterAddress: '请输入公司注册地址',
        enterValid: '请输入有效的返点比例',
        selectAppropriate: '请选择合适的代理商账户',
        onHold: '待处理',
        cVROPC1: '此CVR优化与创建广告出价中',
        cVROPC2: 'OCPC联动，请谨慎调整。',
        cVROPC3: '1）若campaign出价为固定出价，此CVR优化配置广告主不可感知。若广告主将固定出价修改为OCPC，此CVR优化配置将作为OCPC的默认配置。',
        cVROPC4: '2）若campaign出价为OCPC，此CVR优化配置与OCPC联动，广告主可感知，谨慎使用。',
        algorithmic: '算法优化对象',
        changedAfterSetting: '设定后不可更改',
        cpa: '必须是大于0的数',
        completed: '已完成',
        pleaseEnter1: '请输入',
        profitRatio: '利润率必须为数字',
        yueBao1: '(内部资料，请勿随意外泄)',
        yueBao3: '运营红线，素材图片和详细数据指标仅内部查看，不要直接分享给广告主',
        dailySubsidy: '补量天级亏损上限必须为不小于0的数字',
        totalSubsidy: '补量总亏损上限必须为不小于0的数字',
        accelerate: '加速训练倍数必须是0-20之间的整数',
        impBound: '加速训练倍数必须是0-10000之间的整数',
        contains: '包含',
        percentage: '占比',
        creation: '新建',
        processing: '处理中',
        accepting: '验收中',
        clear: '清除',
        mon: '星期一',
        tue: '星期二',
        wed: '星期三',
        thu: '星期四',
        fri: '星期五',
        sat: '星期六',
        sun: '星期日',
        selected: '已选',
        unselected: '未选',
        less1: '由于 Stripe 收取手续费，充值到MediaGo的金额将小于',
        less2: '。',
        whenRecharge: '当账户消费到设定的金额时，Stripe会自动从您在其保存的信用卡中扣除固定金额，自动扣款金额可在自动充值中设置。',
        whenAmount: '当账户消费到您在账户消费金额中设置的金额时，Stripe将自动从您在其自动扣款中保存的信用卡中扣除您在此处设置的金额。请注意，设置的自动充值金额不能低于设置的账户消费金额。',
        autoBillingAmount: '自动扣款金额',
        paymentDetails: '支付详情',
        dueAmount: '由于手续费，实际收到的金额将少于付款金额。',
        selectAdAccount: '选择广告账户',
        minimum: '最少充值金额为$50',
        transferAmount: '手续费全额支付（包括PayPal的费用）',
        paypal1: '为确保 MediaGo 收到',
        paypal2: '的全额款项，您需要支付',
        paypal3: '以支付 PayPal 收取的转账费用。',
        amountPayable: '应付金额',
        transferFee: '手续费',
        rechargeAmount: '充值金额的 2.9% + 每笔交易 $0.30',
        actualAmount: '实际金额',
        cancel: '取消',
        payNow: '立即支付',
        paymentHistoryDetails: '支付历史详情',
        paymentAccount: '支付账户',
        rechargeAmount1: '充值金额',
        amountSpend: 'Amount Spend',
        account: '账户',
        PayPalmore1: '请注意，通过 PayPal 的标准交易费用为交易金额的 2.9% 加上每笔交易 0.30 美元。 想了解更多详情，',
        PayPalmore2: '请访问这里。',
        automaticBilling: '自动充值',
        automaticBilling1: '开启此功能后，Stripe 将自动保存您的信用卡信息，以便下次自动扣款。',
        monthlyOperationalReport: '运营月报',
        currentIncome: '本期收入',
        percentageOfConsumption: '消耗占比',
        lastPeriodIncome: '上期收入',
        yearOnYearLastWeek: '同比上周',
        yearOnYear: '同比上期',
        comparisonFunction1: '对比功能使用tips:',
        comparisonFunction2: 'Step1:左侧选择本期时间区间',
        comparisonFunction3: 'Step2:右侧选择对比的起始时间',
        display: '展开显示饼图数据',
        comparison: '对比',
        categoryOfEvent: '事项类别',
        adAccount: '广告账户',
        category: '行业分类',
        campaignName: '广告计划名称',
        priority: '优先级',
        status: '状态',
        accountManager: 'AM负责人',
        currentPersonInCharge: '当前负责人',
        addTracker: '新建事项',
        uploadImage: '上传图片',
        blackFormat: '屏蔽格式',
        blackPageType: '屏蔽页面类型',
        forstyle: '样式过滤，多个值之间用英文“;”分隔。例如：infopane;river',
        forpage: 'pagetype的过滤，多个值之间用英文“;”分隔。例如：edgechrmtp;25;36',
        startDate: '开始时间',
        accountSource: '账户来源',
        accountNature: '账户性质',
        contractTitle: '合同主体',
        aPIToken: 'APIToken',
        serviceType: '服务类型',
        businessManagement: '商务管理',
        advertiser: '广告主',
        aPIhasinstalled: '已设置API',
        withoutSettingAPI: '未设置API',
        openAccountIndependently: '自主开户',
        inviteToOpenAccount: '邀请开户',
        searchName: '搜索账户/公司名称',
        previewImgTitle: '清除',
        accountName: '账户名称',
        accountID: '账户ID',
        companyName: '公司名称',
        // country: '国家',
        country: '地区',
        billingEmail: '财务结算邮箱',
        organizationName: '客户来源公司',
        businessRebate: '商务返点',
        email: '开户人邮箱',
        paymentMethod: '支付方式',
        deliverymethod: '投放方式',
        accountTimeZone: '账户时区',
        businessManager: '商务经理',
        industryCategory: '行业分类',
        creationTime: '创建时间（EST）',
        operate: '操作',
        accountDetails: '账户详情',
        accountInformation: '账户信息',
        organizationWebsite: '官网链接',
        accountHolderInformation: '开户人信息',
        totalAccountBudget: '账户总预算',
        timeZone: '时区',
        company: '公司',
        mediaGoNon: 'MediaGo非对接公司',
        registeredAddressOfCompany: '公司注册地址',
        companyAddress: '公司经营地址',
        emailaddress: '请输入邮箱',
        prepay: '预付',
        postpay: '到付',
        selfService: '自投',
        managed: '代投',
        mark1: '账户创建API的条件:1.账户性质为广告主(二次确认)2.该账户状态为开启状态3.该账户的消耗达到5000美金',
        generateToken: '生成Token',
        mark2: '注:投放在Taboola的广告无法使用API拉取数据',
        addUserRW: '添加用户将自动为该用户开通该账户的可读权限',
        otherUsers: '其他用户',
        operator: '操作人',
        operatingTime: '操作时间',
        specificIndustry: '具体行业',
        add: '添加',
        automaticReporting: '创建报告',
        reportTimeZone: '报告时区',
        recipient: '收件人',
        sendingFrequency: '发送频率',
        stopsending: '关闭后就不再发送',
        consistent: '与账户时区一致',
        mark4: '可输入多个邮箱，用逗号隔开',
        daily: '每日',
        monthly: '每月',
        advertiserName: '广告主名称',
        advertiserEmail: '广告主邮箱',
        advertiserApplication: '广告主申请',
        loginInfo: '登录信息',
        applicationCompleted: '完成申请',
        postpay1: '后付',
        accountType: '账户类型',
        reportingTimezone: '报告时区',
        selectthetimezone: '选择时区来管理您的广告系列。 您的设置无法修改。',
        organizationName1: '机构名称',
        asthiswil: '由于这将直接影响计费，请仔细准确地输入组织详细信息以反映正式的注册文件。',
        organizationAddress: '机构地址',
        asthiswilldirectly: '由于这将直接影响计费，请仔细准确地输入组织详细信息以反映正式的注册文件。',
        organizationWebsite1: '组织网站',
        initialCreditLine: '初始信用额度',
        ifyou: '如果您将初始信用额度设置为大于 0，则会向 AM 组和您发送通知电子邮件。',
        advertisernameisrequired: '广告商名称为必填项',
        registeraddressisrequired: '注册地址为必填项',
        pleaseEnter: '请输入以 http: 或 https: 开头的有效公司网站',
        workEmail: '工作电子邮件',
        workEmail1: '（这将用作用户名）',
        firstName: '名',
        lastName: '姓',
        password: '密码',
        pleaseenteravalidemailaddress: '请输入有效的邮箱地址',
        pleaseenteravalidfirstname: '请输入有效的名',
        pleaseenteravalidlastname: '请输入有效的姓',
        specialcharacterandnumber: '请输入至少 8 个字符，混合字符、特殊字符和数字',
        next: '下一页',
        previous: '上一页',
        submit: '提交',
        officialWebsite: '官网链接',
        pleaseenteryouremail: '请输入邮箱',
        pleaseentercompanyURL: '请输入公司网址',
        pleaseentertheaccountname: '请输入账户名称',
        pleaseenterbudget: '请输入预算',
        deliveryMethod: '投放方式',
        pleaseselectacompany: '请选择公司',
        addCompany: '新增公司',
        ifthere: '若没有，请新增公司后，再选择',
        customerSource: '客户来源',
        terminalclient: '直客',
        agent: '代理商',
        contractType: '合同主体',
        contractType2: '合同主体',
        adding: '添加用户将自动为该用户开通该账户的可读权限',
        pleasechooseanotherrole: '请选择其他用户角色',
        registeredaddressofthecompany: '公司注册地址',
        companyBAddress: '公司经营地址',
        bysubmitt: '提交此表格，即表示我同意 MediaGo 的隐私政策和条款。',
        authorizedUser: '已授权用户',
        unauthorizedUser: '未授权用户',
        name: '姓名',
        usersource: '用户来源',
        loginMethod: '登录方式',
        workEmail2: '工作邮箱',
        companyName1: '公司名称（平台录入名称）',
        position: '职位',
        numberOfWritableAccounts: '可写账户数量',
        numberOfReadableAccounts: '可读账户数量',
        role: '角色',
        searchCompanyName: '搜索公司名称+姓名+邮箱',
        userInvitationLink: '用户邀请链接',
        details: '详情',
        userRegistrationInformation: '用户注册信息',
        userSource: '用户来源',
        lastName1: '姓氏',
        name1: '名字',
        companyWebsite: '公司网址',
        telephone: '电话',
        userRole: '用户角色',
        accountAllocation: '账户分配',
        bMAccount: 'BM账户',
        writableAccount: '可写账户',
        readableAccount: '可读账户',
        addingordeletinga: '对BM账户进行增删操作将影响该用户对所有代理商账户为该BM账户的可读权限，请谨慎操作',
        edit: '编辑',
        ordinal: '序号',
        roleName: '角色名称',
        assignedNumber: '分配人数',
        changeTime: '修改时间',
        createRole: '创建角色',
        pleaseentertherolename: '请输入角色名称',
        mediaGoAdvertiser: 'MediaGo广告主',
        baiduReviewer: '百度审核员',
        microsoftReviewer: 'Microsoft 审核员',
        baiduRD: '百度RD',
        baiduBD: '百度BD',
        baiduAM: '百度AM',
        financialStaff: '财务',
        admin: '管理员',
        dataAnalyst: '数据分析员',
        mGIDReviewer: 'MGID审核员',
        parentologyReviewer: 'Parentology审核员',
        helpCenter编辑: '帮助中心编辑',
        financialReceivable: '财务应收',
        roleManager: '角色管理员',
        prebidTestReviewer: 'Prebid 测试审核员',
        rTBDemandReviewer: 'RTB审核员',
        zipporReviewer: 'Zippor审核员',
        adPushupReviewer: 'AdPushup审核员',
        advertiser1: '代理商广告主',
        pubGeniusReviewer: 'PubGenius审核员',
        insticatorReviewer: 'Insticator审核员',
        streamAmpReviewer: 'StreamAmp审核员',
        financialStaffPaypal: '财务结算-Paypal',
        eCommerceAM: '电商AM',
        eCommerceROIReport: '电商ROI报表',
        adminR: '可读权限管理员',
        internalTestAdvertiser2: '内测广告主2',
        offlineProject: '财务-offline项目',
        bulkReview: '批量审核',
        domainWhitelist: 'mobile domain白名单',
        financialReport: '财务报表',
        stripeAutoBilling: '内测广告主-stripe自动支付',
        discoveryAdvertiser: 'Discovery广告主',
        discoveryFinancalTest: 'Discovery财务测试',
        discoveryOldAdvertisers: 'Discovery旧广告主',
        discoveryOldAgency: 'Discovery旧代理店广告主',
        oldDiscoveryAgency: 'Discovery旧代理店',
        discoveryNewadvertisers: 'Discovery新广告主',
        platformExperienceRead: '平台体验专用-Read',
        bidbydomain: '分域名出价',
        newDiscoveryAdvertiser: '新Discovery广告主',
        dashboard: '控制台',
        reviewDetails: '审核详情',
        campaignDetails: '广告计划详情',
        campaignCopy: '复制广告计划',
        campaignEdit: '广告计划编辑',
        adStatus: 'Ad投放状态',
        domainBlock: '域名屏蔽',
        domainTabsspColumn: 'domain页ssp属性',
        reviewDetails1: '审核详情媒体分类',
        adColumnSize: 'ad设置列 尺寸 选项',
        dimensionFilter: '多维度筛选',
        conversiontype: '转化类型',
        oCPC: 'OCPC',
        domainSetCampaignCpc: 'Bid By Domain',
        eCPC: 'ECPC',
        adOperation: '运营平台',
        analysis: '运营分析',
        blackwhiteconfig: '配置黑白名单',
        campaignOperationConfig: '广告计划运营配置',
        taboolaAdPull: 'Taboola广告拉取',
        blacklistconfig: '广告计划PageId黑名单配置',
        reminder: '广告计划异常提醒',
        log: '广告计划操作日志',
        advertiserOperationRecord: '广告主运营记录',
        multiDimensionalreport: '多维度数据报表',
        tracker: '事项追踪',
        bidAnalysis: '竞价分析',
        diagnosis: '广告诊断',
        innerFAQ: '',
        sample: '抽样平台',
        newCampaign: '创建广告',
        win10adTargeting: 'win10定向投放',
        userPackage: '用户包',
        more: '多素材',
        // domainwhitelist: '域名白名单',
        impURL: '第三方追踪',
        // locationJapan: 'locationJapan',
        // monthlyCapStatus: '月预算',
        // objective: '目标',
        // audience: '受众',
        pixel: 'Pixel',
        conversionlist: 'Conversion列表',
        readlist: 'Read列表',
        newConversion: '创建转化',
        dataReport: '数据报表',
        lPManagement: '内容管理',
        oPManagement: 'OP Management',
        productManagement: '产品管理',
        domainManagement: '域名管理',
        customerManagement: '客户管理',
        rOIAnalysis: 'ROI报表',
        paymentManagement: '账款管理',
        reviewAds: '审核广告',
        startReviewing: '开始审核',
        detail: '详情',
        category1: '分类',
        geoEdge: 'Geo Edge',
        sensitiveCategory: '敏感分类',
        auditLabel: '审核标签',
        displayallauditedimg: '一次性展示所有审核图片',
        authorization: '权限管理',
        accountList: '账户管理',
        addAccount: '新建账户',
        link: '开户邀请链接',
        authorize: '授权',
        ignore: '忽略',
        copyInvitationLink: '复制邀请链接',
        bDAccountOpening: 'BD开户',
        userList: '用户权限',
        roleList: '角色管理',
        delete: '删除',
        create: '创建',
        billing: '账户充值',
        recharge: '充值',
        stripe: 'Stripe',
        autoBilling: '自动充值',
        multiCurrencySwitcher: '多货币切换',
        finance: '财务管理',
        creditLine: '财务风控',
        creditLine1: 'Credit Line',
        coupon: 'Coupon',
        rOIReport: '财务报表',
        receivable: '应收款',
        payable: '应付款',
        offline: 'offline',
        bannerViewableTest: 'Banner可视化测试',
        viewableTest: '可视化测试',
        hibernation: '休眠状态',
        aDCallback: '广告召回状态',
        adBidConfig: '广告位出价配置',
        review: '审核',
        history: '历史记录',
        generateLPWrapper: '生成落地页网页',
        getEmail: '管理员获取邮箱验证码',
        write: '可编辑',
        read: '可检视',
        currencyType: '结算货币类型',
        open: '自主开户',
        inviteTo: '邀请开户',
        uSD: '美元',
        cNY: '人民币',
        jPY: '日元',
        sGD: '新加坡元',
        hKD: '港币',
        tWD: '台币',
        tHB: '泰铢',
        iDR: '印度尼西亚盾',
        kRW: '韩元',
        mYR: '令吉',
        totalBalance: '账户余额',
        totalRecharge: '充值金额',
        availablequota: '可用额度',
        creditLineSetting: 'Credit Line设置',
        creditLineRecord: 'Credit line 修改记录',
        rechargeRecord: '充值记录',
        pleaseentertheamounttorecharge: '请输入要充值的金额',
        validAmount: '请输入有效数值',
        asof: '截止于',
        remark: '备注',
        pleaseenteraremark: '请输入备注',
        uTSDataUpdateTime: 'UTS数据更新时间',
        totalCoupon: 'Coupon总额',
        couponConsumption: 'Coupon已消耗',
        effectiveCouponBalance: '有效Coupon余额',
        effectiveTime: '生效时间',
        expireDate: '过期时间',
        offlineTime: '下线时间',
        profitMarginSetting: '利润率设置',
        pleaseenterprofitmargin: '请输入利润率',
        profitMarginRecord: '利润率修改记录',
        currentIncome1: '当期收入',
        currentCost: '当期成本',
        currentProfit: '当期利润',
        profitMargin: '利润率',
        impression: '展示数',
        clicks: '点击',
        totalHistory: '历史总流水',
        totalHistoricalIncome: '历史总收入',
        totalHistoricalCost: '历史总成本',
        historicalProfitMargin: '历史利润率',
        lastUpdateTime: '上次更新时间',
        enterAccountCompanyName: '输入账户公司名',
        media: '投放媒体',
        currentIncome2: '当期收入=账户当期消耗（未减 coupon）',
        pullAds: '拉取广告',
        interfaceSetting: '接口配置',
        adInterface: '广告接口',
        testField: 'test字段',
        campaignId: '广告计划ID',
        aDId: '广告ID',
        onlineEnvironment: '线上环境',
        testEnvironment: '测试环境',
        developmentEnvironment: '开发环境',
        testNoBilling: '1-测试-不计费',
        realBilling: '0-真实-计费',
        multipleids: '多个id，用英文逗号分隔。例：111,222',
        multipleids1: '多个id，用英文逗号分隔。例：111,222',
        pageParameters: '页面参数',
        placement: '平台',
        pageURL: '页面URL',
        category2: '类型',
        adTypeSetting: '广告样式配置',
        thereserveprice: '底价，不填则默认0。eg:0.43',
        fixedIndex: '固定index：英文逗号分隔，不填默认index',
        geographicalConfig: '地理位置配置',
        zipCode: '邮编',
        iP: 'IP',
        language: '语言',
        unitedStatesState: '美国-州',
        noneMSNMayNotTransmit: '无-MSN可能不传',
        stateData: '各州数据',
        deviceInformation: '设备信息',
        userAgent: '用户代理',
        personalInformation: '个人信息',
        microsoftUID: 'Microsoft UID',
        copymuid: '复制muid',
        refreshmuid: '刷新muid',
        fuzzyMatch: '模糊匹配',
        sspId: 'Ssp Id',
        selectRecallStatus: '召回状态筛选',
        notrecallable: '不可召回',
        recallable: '可召回',
        inquire: '查询',
        column: '设置列',
        campaign: '广告计划',
        ad: '广告',
        generate: '生成',
        getVerificationCode: '验证码获取',
        verificationCode: '验证码',
        none: '无',
        obtain: '获取',
        pid: 'Pid',
        priceStatus: '出价状态',
        trainPriceStatus: '训练价格状态',
        sSPId: 'Ssp Id',
        imageSize: '图片大小',
        format: '格式',
        pidProfit: 'Pid 利润',
        cTRSwitch: '点击率开关',
        priceMode: '出价模式',
        toBeReviewed: '待评审',
        belowReservePrice: '低于底价',
        reset: '重置',
        search: '查询',
        addPid: '新建Pid',
        startReview: '开始评审',
        adId: '广告 ID',
        mediaAccount: '媒体',
        mediaAccountName: '媒体名称',
        sellerId: 'Seller ID',
        site: '站点',
        creativeID: '广告素材 ID',
        browser: '浏览器',
        platform: '平台',
        oS: '操作系统',
        stateRegion: '州地区',
        cost: '成本',
        revenue: '收入',
        click: '点击',
        vCPM: 'VCPM',
        cTR: '点击率',
        avgCPC: '平均CPC',
        reqAdNum: '广告请求数',
        reqNum: '请求数',
        resNum: '响应数',
        conversions: '转化数',
        conversionRate: '转化率',
        conversionPrice: '转化价格',
        viewContent: '查看内容',
        appInstall: '安装应用',
        completeRegistration: '完成注册',
        addToCar: '加入购物车',
        addPaymentInfo: '添加付款信息',
        startCheckout: '开始结帐',
        purchase: '购买',
        addToWishlist: '加入愿望清单',
        lead: '潜在用户',
        other: '其他',
        addTitle: '添加标题',
        benchmark1: 'Benchmark筛选规则：',
        benchmark2: '分品类导出各品类下所有的Campaign数据作为原始数据',
        benchmark3: '对Campaign原始数据进行筛选',
        benchmark4: '保留审核通过的Campaign（排除拒审，归档）',
        benchmark5: '保留本月Vimp大于1W，且Clicks大于100，且Spend大于$100的Campaign',
        benchmark6: '将剩下的Campaign分品类进行CPC，和CTR的排序，得出CPC和CTR范围',
        benchmark7: '优秀素材筛选规则：',
        benchmark8: '分品类导出各品类下所有的Campaign数据作为原始数据',
        benchmark9: '对Campaign原始数据进行筛选',
        benchmark10: '保留审核通过的Campaign（排除拒审，归档）',
        benchmark11: '分品类筛选出各品类下本月消耗Top10的Campaign',
        benchmark12: '对Top Campaign下的Ad进行筛选，筛选出vimp大于2W的Ad',
        benchmark13: '分品类挑选Top Ad：确保creative不重复，如果Creative重复，则排名顺延',
        benchmark14: 'Content：将筛选出的Ad统一根据vctr排序，挑选vctr排序Top10的Ad。',
        benchmark15: 'E-commerce：将筛选出的Ad根据cvr和vctr分别排序',
        benchmark16: '挑选出cvr排序Top5的Ad',
        benchmark17: '挑选出vctr排序Top5的Ad',
        benchmark18: 'Finance：将筛选出的Ad统一根据vctr排序，挑选vctr排序Top10的Ad。',
        locationJapan: '日本地区',
        monthlyCapStatus: '月预算',
        domainwhitelist: '域名白名单',
        objective: '目标',
        audience: '受众',
        remarkisrequired: '备注不能为空',
        amountRequired: '请输入完整的需要调整的金额',
        addUser: '新建用户',
        privacyPolicy1: '提交此表格，即表示我同意MediaGo 的',
        privacyPolicy2: '隐私政策',
        privacyPolicy3: '和',
        privacyPolicy4: '条款',
        privacyPolicy5: '。',
        previous1: 'Thank you for registering!',
        previous2: 'If you have any further questions,',
        previous3: 'please contact',
        previous4: 'We’ll reply to you as soon as possible.',
        startAuthorizing: '开始授权',
        searchbyAccountType: '搜索账户类型',
        youImageHere: '您的图片在这',
        targetCPA: '目标CPA',
        targetMCPA: '目标MCPA',
        confirmSubmission: '提交后，算法优化对象不可更改。确认提交？',
        accountName1: '账户名称',
        campaginID: '广告计划ID',
        currentManager: '当前责任人',
        addRole: '添加角色',
        loginAddress: '登录地址',
        permanentlyDelete: '当前操作将永久删除该角色，请点继续',
        goon: '继续',
        verifyThat: '确认生成API toke',
        applicationTitle: '完成申请，请保存以下广告登录信息',
        noApecificAccount: '无特定账户',
        noMoreThan: '不能超过',
        pleaseSelectItemCategory: '请选择事项类别',
        pleaseSelectStatus: '请选择状态',
        pleaseSelectPriority: '请选择优先级',
        pleaseSelectResponsiblePeople: '请选择当前责任人',
        theAbnormalExposure: '曝光异常',
        blackFormat1: '请输入Black Format',
        blackPlacement: '请输入Black Placement',
        keyGoal: '核心目标长度不能超过100个字符',
        advertisingCategory: '投放产品长度不能超过100个字符',
        communicationChannel: '沟通渠道长度不能超过100个字符',
        otherInfo: '其他信息长度不能超过200个字符',
        expireTime: '请选择失效时间',
        profitRate: '请输入需要修改的利润率',
        amount: '请输入需要充值的金额',
        editCreditline: '请输入需要设置的credit line',
        recordValue: '当前Credit Line',
        totalRecharge1: '累计充值金额',
        cannotBeModified: '确认后将无法修改，确认吗？',
        checkNumber: '请输入有效的数字',
        password1: '至少一个大写/小写英文字',
        password2: '至少一个特殊标点符号',
        password3: '至少一个数字',
        password4: '总共至少8个英文/符号/数字组合',
        yueBao2: '创意推荐',
        undef: '未定义',
        affiliate: '联盟',
        affiliateName: '联盟名称',
        billingPeriod: '结算期',
        conversionDeduction: '转换扣除',
        income: '收入',
        offerId: 'Offer ID',
        offerName: 'Offer 名称',
        benchmark: '基准',
        content: '内容',
        ecommerceCtr: '电商点击率',
        ecommerceCvr: '电商转化率',
        'lead gen': '潜在客户',
        noMore: '运营记录描述长度不能超过800个字符',
        noUndef: '运营记录描述不能为空',
        comparison1h: '1h对比',
        comparison24h: '24h对比',
        comparison1w: '1w对比',
        now: '当前',
        optimizationType: '请设定算法优化对象,设定后,不可更改',
        generateAnalysisReport: '生成分析报告',
        reportEmail: '（报告将发送至你的登录邮箱）',
        confirmSendingReport: '确认发送报告',
        lineChart1: '没有可显示的数据',
        lineChart2: '请选择广告 ID 和展示位 ID 以查看数据',
        searchPlacementID: '搜索展示位ID',
        sampleId: '抽样ID',
        sampleName: '抽样名称',
        bucketId: '桶ID',
        description: '描述',
        conf: '配置',
        type: '类型',
        flow: 'flow',
        correctFormat: '请输入正确格式，例如：12-20。',
        noNumberLR: '左边或右边为非数字！',
        defaultHomePage: '默认主页',
        homePage: '主页',
        articlePage: '文章页',
        galleryPage: '图库页',
        videoPlayerPage: '视频播放页',
        kids: '儿童类',
        forGood: 'For Good',
        basePrice: '底价, 不填则默认0, eg:0.43',
        fixedIndex1: '固定index: 英文逗号分割，不填默认index递增1，eg:2,3',
        'river-half': 'river-half',
        river: 'river',
        rivertall: 'rivertall',
        'river-dbl': 'river-dbl',
        infopane: 'infopane',
        flexaside: 'flexaside',
        copyDone: '复制完成',
        checkStatus1: 'CPC cap详细数据',
        checkStatus2: '天花费',
        checkStatus3: '天Cap',
        checkStatus4: '总花费',
        checkStatus5: '总Cap',
        checkStatus6: '特殊campaign不能设置休眠状态',
        adStatus1: 'Ad开启状态',
        dormancy: '休眠状态on｜off',
        recallStatus: '可召回状态',
        clickCapStatus: 'cpc cap状态',
        impCapStatus: 'cpm cap状态',
        under: '未跑满',
        full: '已跑满',
        timeStatus: '时间排期状态',
        platoonPeriod: '排期内',
        outside: '排期外',
        uniformlyStatus: '匀速状态',
        uniformlyStatus1: '匀速跑满，受控中',
        campaignStatus: '广告计划开启状态',
        allRejected: '全拒绝',
        allThrough: '全通过',
        'Approved+pendingPeview': '通过+待审',
        'Pass+Reject': '通过+拒绝',
        currentMediaPass: '当前媒体通过',
        currentMediaPendingReview: '当前媒体待审',
        currentMediaRejection: '当前媒体拒绝',
        totalAccountBalanceStatus: '账户总余额状态',
        accountStatus: '账户开启状态',
        auditPassSsps: '通过审核的媒体',
        index: 'Index',
        newAdvertisingSpace: '新增广告位',
        json: '该输入框支持json格式',
        json1: '（支持输入多条）',
        json2: '请输入正确的JSON数',
        pidProfit1: '第{i}行Pid Profit值不能大于10或者小于-10',
        beforeTheChange: '更改前',
        afterTheChange: '更改后',
        modifier: '修改人',
        modifiedTime: '修改时间',
        newURL: '新链接',
        taboolaLP: 'Taboola LP',
        pageId: '页面Id',
        creationDate: '创建时间',
        product: '产品',
        onLp: '是否开启 LP 挽留弹窗',
        onBanner: '是否开启固定右侧banner',
        addNewProduct: '添加新的产品',
        editProuduct: '编辑产品',
        deleteProduct: '删除产品',
        addNewDomain: '添加新的域名',
        editDomain: '编辑域名',
        domainId: '域名Id',
        favicon: '图标',
        operations: '操作',
        tips: '建议尺寸：90 * 90',
        tips1: '上传图片只能是 JPG / JPEG / PNG 格式!',
        tips2: '上传图片大小不能超过 2MB!',
        enterDomainName: '请输入域名',
        enterValidDomainName: '请输入有效域名',
        pleaseUploadPictures: '请上传图片',
        pleaseEnterFooter: '请上传Footer',
        emailTemplate1: '在订单',
        emailTemplate2: '和',
        emailTemplate3: '订单',
        domain: '请选择域名',
        emailAddress: '请输入发件箱地址',
        emailAddress1: '请输入正确的邮箱地址',
        enterPassword: '请输入邮件密码',
        templateName: '请输入邮件模板名称',
        time: '请选择时间周期',
        storeLogo: '请上传店铺Logo',
        storeName: '请输入店铺名称',
        emailTitle: '请输入邮件标题',
        emailContent: '请输入邮件正文',
        emailImage: '请上传邮件海报',
        footer: '请输入邮件Footer',
        time1: '请选择时间',
        lastUpdateDate: '最后更新时间',
        selectAccountName: '选择账户名称',
        selectProductName: '选择产品名称',
        selectCampaignName: '选择广告计划名称',
        onlineDate: '上线日期',
        order: '订单',
        adSpendBudget: '广告预算消耗',
        adSpend: '广告花费',
        atv: '客单价',
        enterLandingPage: '进入落地页',
        enterOfferPage: '进入Offer页面',
        impCost: '展示花费',
        merchandise: 'Merchandise',
        numberOfProduct: '产品数',
        numberOfOrders: '订单数',
        logisticFee: '物流费用',
        productPrice: '产品价格',
        startPurchase: '开始购买',
        avgCTR: '平均点击率',
        vimpCost: '真实展示花费',
        prodcutName: '产品名称',
        totalAmount: '总金额',
        disputeAmount: '争议金额',
        disputeRate: '争议率',
        disputeRate1: '争议率（按争议日期计算）',
        disputeRate2: '争议率（按收款时间计算）',
        paymentCount: '支付数',
        disputeCount: '争议数',
        disputeCount1: '重复争议数',
        disputeCount2: '欺诈争议',
        disputeCount3: '一般争议',
        disputeCount4: '信用未处理争议数',
        notRecCount: '未收到货物争议数',
        productUnacceptableCount: '产品不可接受争议数',
        subscriptionCanceledDisputeCount: '取消订阅数',
        unrecognizedDisputeCount: '未识别争议数',
        notReceivedDisputeAmount: '未收到争议金额',
        notAsDescribedDisputeAmount: '描述不符争议金额',
        unauthorizedDisputeAmount: '未授权争议金额',
        creditNotProcessedDisputeAmount: '信用未处理争议金额',
        duplicateTransactionDisputeAmount: '重复交易争议金额',
        incorrectAmountDisputeAmount: '不正确金额争议金额',
        paymentByOtherMeansDisputeAmount: '以其他方式支付争议金额',
        canceledRecurringBillingDisputeAmount: '取消订阅争议金额',
        problemWithRemittanceAmount: '汇款金额问题',
        otherDisputeAmount: '其他争议金额',
        fraudAmount: '欺诈争议金额',
        fraudRate: '欺诈争议率',
        // 需要翻译
        comparison1d: '1d对比',
        comparison7d: '7d对比',
        comparisons: '周期对比'
    },
    serviceRecord: {
        tracker: '跟踪器',
        backgroundInformation: '背景信息',
        edit: '编辑',
        accountName: '账户名称',
        accountHoldersEmail: '开户人邮箱',
        accountTimeZone: '账户时区',
        // country: '国家',
        country: '地区',
        industryCategory: '行业分类',
        accountManager: 'AM负责人',
        businessManager: '商务经理',
        coreTarget: '核心目标',
        launchProduct: '投放产品',
        communicationChannel: '沟通渠道',
        otherInformation: '其他信息',
        serviceRecord: '服务记录',
        serviceType: '服务类型',
        deliveryStatus: '投放动态',
        meetingCommunication: '会议/沟通',
        changeOfManager: '接口人变更',
        other: '其他',
        timeline: '时间线',
        operationRecordDescription: '运营记录描述',
        add: '添加',
        config: 'Account维度的配置只对新创建的campaign生效。旧的campaign的白名单保存不变。',
        whitelist: '白名单',
        individualChoice: '可多选',
        supportbatch: '支持批量写入，使用;分隔',
        percentageOfMedia: '媒体占比',
        pleaseenter: '请写入1-99, 数字为该campaign在媒体可佔据的点击比例（单位 %)',
        clientid: '客户ID:',
        clientSecret: '客户机密:',
        accountid: '账户ID:',
        added1: '已添加：',
        profitMarginOperation: '利润率运营',
        profitMargin: '利润率',
        replenishmentday: '补量天级亏损上限',
        topUptotallosscap: '补量总亏损上限',
        trainingOperations: '训练运营',
        speedUpTrainingMultiple: '加速训练倍数',
        retrainingthreshold: '重新训练阀度值',
        cVROptimization: 'CVR优化',
        switch: '开关'
    },
    tracker: {
        trackerCategory: '事项类别',
        priority: '优先级',
        status: '状态',
        industryCategory: '行业分类',
        onlyShow: '只看自己',
        include: '包含历史责任',
        afterchecking: '勾选后，则只能进行负责人搜索，无法进行账户搜索。',
        newItem: '新建事项',
        ordinal: '序号',
        adAccount: '广告账户',
        updateTime: '更新时间',
        accountManager: 'AM负责人',
        currentManager: '当前负责人',
        creationTime: '创建时间',
        operate: '操作',
        update: '事项更新',
        consumptionRiseAnalysis: '消耗上升分析',
        consumptionFallAnalysis: '消耗下降分析',
        lowBudget: '预算消耗不掉',
        bugFeedback: '平台bug反馈',
        functionOptimization: '功能优化建议',
        material: '素材设计需求',
        strategicSupport: '策略倾斜给量',
        technicalSupport: '技术支持',
        conversionRelated: '转化相关',
        other: '其他',
        request: '请求数',
        callback: '召回数',
        imp: '展示数',
        vimp: '可视展示数',
        click: '点击',
        revenue: '收入',
        selectPublisher: '选择媒体',
        selectPage: '选择页面',
        selectFormat: '选择格式',
        selectAccount: '选择账户',
        selectCampaign: '选择广告计划',
        search: '搜索',
        reset: '重置',
        byPublisher: '按出版商',
        byPage: '按页面',
        byFormat: '按格式',
        byAccount: '按帐户',
        byCampaign: '按活动',
        more: '更多',
        columns: '列',
        publisherName: '媒体名称',
        page: '页面',
        format: '格式',
        accountName: '账户名称',
        campaignName: '广告计划名称',
        callbackRate: '召回率',
        winRate: '竞得率',
        viewableRate: '可视率',
        iCTR: '展示点击率',
        vCTR: '可视点击率',
        callbackRateROC: '召回率变动速率',
        winRateROC: '竞得率变动速率',
        viewableRateROC: '可见率变动速率',
        vCTRROC: '可视点击变动速率',
        iCTRROC: '展示点击变动速率',
        cost: '成本',
        rOI: '投资回报率',
        icpm: 'icpm',
        cRpm: 'cRpm',
        iRpm: 'iRpm',
        revenueROC: '收入变动速率',
        revenueDiff: '收入差异',
        requestDiff: '请求差异',
        callbackDiff: '召回差异',
        impDiff: '展示差异',
        vimpDiff: '可视展示差异',
        clickDiff: '点击差异',
        profit: '利润',
        profitDiff: '利润差异',
        accountID: '账户ID',
        campaignID: '广告计划 ID',
        accountNameData: 'accountNameData',
        conversion: '转化',
        conversionDiff: '转化差异',
        conversionRate: '转化率',
        conversionRateROC: '转化率变动速率',
        costPerConversion: '转化成本',
        costPerConversionDiff: '转化成本差异',
        viewContent: '查看内容',
        appInstall: '安装应用',
        completeRegistration: '完成注册',
        addToCar: '加入购物车',
        addPaymentInfo: '添加付款信息',
        startCheckout: '开始结帐',
        purchase: '购买',
        addToWishlist: '加入愿望清单',
        lead: '潜在用户',
        submissionDate: '素材提交日期',
        reviewDate: '审核日期',
        startDate: '开始时间',
        endDate: '结束时间',
        geoEdgeAlert: 'GeoEdge报警'
    },
    monthlyBill: {
        downloadTemplate: '下载模板',
        initData: '初始数据',
        currentData: '当前数据',
        operationLog: '操作记录',
        placeholderTip: '公司名/user名搜索',
        uploadErrorTip: '上传失败，请修改后重新上传',
        uploadTip: '仅支持可编辑部分上传',
        uploadTypeTip: '仅支持 csv 格式',
        selectFlie: '选择文件',
        uploadResult: '上传结果',
        editTip: ' 修改了 ',
        editFormTip: ' 由 ',
        editToTip: ' 改为 ',
        remark: '备注',
        upload: '导入',
        line: '第'
    },
    agency: {
        email: '信息同步邮箱'
    },
    // AI-CreativeGo
    aiCreativeGo: {
        Japan: '日本',
        twd: '台湾',
        hk: '香港',
        my: '马来西亚',
        Japanese: '日语',
        chinese: '简体中文',
        zhHant: '繁体中文',
        // title
        hearderTitle: 'AI生成广告标题',
        contentTitle: 'AI生成标题',
        // form-labels
        landingPage: '落地页链接',
        location: '地区',
        language: '语言',
        sellingPoint: '产品卖点',
        titleSample: '标题样例',
        audience: '目标受众',
        gender: '性别',
        age: '年龄',
        productName: '产品名称',
        campaignCategory: '广告类别',
        // btns
        createBtn: '生成标题',
        copyAllBtn: '复制全部标题',
        // tips
        landingPageNoneTip: '落地页链接不能为空',
        landingPageRuleTip: '落地页链接输入框不支持包含<>',
        languageNoneTip: '请选择一种语言',
        sellingPointTip: '支持批量写入，使用英文,分隔',
        titleSampleTip: '请提供3个标题供popIn Creative Maker学习，每个标题1行',
        copied: '复制成功',
        requestErrorTip: '页面繁忙，请稍后再试',
        all: '不限',
        // 目标受众-option:
        officeWorkers: '上班白领',
        Homemakers: '家庭主妇',
        elderlyPeople: '老年人',
        sportsEnthusiasts: '运动爱好者',
        investors: '投资者',
        entrepreneurs: '创业者',
        artistsAndDesigners: '艺术家和设计师',
        technologyEnthusiasts: '科技爱好者',
        travelEnthusiasts: '旅行爱好者',
        // gender-option:
        male: '男性',
        female: '女性',
        // age-option:
        ageRange1: '18岁以下',
        ageRange2: '18到24岁',
        ageRange3: '25到44岁',
        ageRange4: '45到64岁',
        ageRange5: '65到85岁',
        ageRange6: '85岁以上',
        // campaignCategory-option
        // aiCreativeGo下所对应的日文较constants的更为准确
        autoselectronics: '汽车与电子类',
        business: '商业类',
        beautyFashion: '美容与时尚类',
        entertainment: '娱乐类',
        economyMoney: '经济与金钱类',
        election: '选举类',
        education: '教育类',
        finance: '金融类',
        foodDrink: '食物和饮品类',
        homepage: '主页类',
        health: '健康类',
        homeLifestyle: '家居与生活类',
        loveMarriage: '爱情与婚姻类',
        music: '音乐类',
        movies: '电影类',
        news: '新闻类',
        politicsSocial: '政治与社会类',
        sports: '运动类',
        travel: '旅行类',
        TV: '电视类',
        video: '视频类',
        weather: '天气类'
    },
    ...state,
    ...country
};